<template>
  <v-card elevation="0" outlined class="pt-3 pb-5">
    <v-row v-if="status === 'waiting'">
      <v-col class="d-flex justify-end pb-0 pt-2 pr-5">
        <v-icon
          v-if="!isEdit"
          color="grey darken-3"
          size="1.3rem"
          @click="changeEdit()"
          >mdi-pencil-outline</v-icon
        >
        <v-icon
          v-if="isEdit"
          color="green darken-3"
          size="1.3rem"
          @click="saveStatus"
          >mdi-check</v-icon
        >
        <v-icon
          v-if="isEdit"
          color="red darken-3"
          size="1.3rem"
          @click="changeEdit()"
          >mdi-close</v-icon
        >
      </v-col>
    </v-row>
    <v-card-subtitle class="py-0"> สถานะ </v-card-subtitle>
    <v-card-title v-if="!isEdit" class="justify-center">
      <h3 class="text-center" :class="statusColor">
        {{ statusFormat(status) }}
      </h3>
    </v-card-title>
    <v-card-title v-if="isEdit" class="justify-center">
      <v-select
        :items="statusItems"
        item-text="text"
        item-value="value"
        v-model="selected"
      >
      </v-select>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: "pending",
    },
  },
  computed: {
    statusItems() {
      return [
        {
          text: "คอนเฟิร์ม",
          value: "confirmed",
        },
        {
          text: "ปฏิเสธ",
          value: "rejected",
        },
        {
          text: "ยกเลิก",
          value: "canceled",
        },
      ];
    },
    statusColor() {
      switch (this.status) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
  },
  data() {
    return {
      isEdit: false,
      selected: "confirmed",
    };
  },
  methods: {
    statusFormat(val) {
      switch (val) {
        case "waiting":
          return "รอคอนเฟิร์ม";
        case "pending":
          return "รอชำระเงิน";
        case "paid":
          return "ชำระเงินแล้ว";
        case "confirmed":
          return "ยืนยันการจอง";
        case "canceled":
          return "ยกเลิก";
        case "rejected":
          return "ปฏิเสธ";
        default:
          return "";
      }
    },
    saveStatus() {
      this.statusShow = this.selected;
      this.isEdit = false;
      this.$emit("input", this.selected);
    },

    changeEdit() {
      this.isEdit = !this.isEdit;
    },
  },
};
</script>
<style scoped>
.card-text {
  font-weight: 600;
}
</style>
