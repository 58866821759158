<template>
  <v-container class="mt-4">
    <Loading v-if="loading" />
    <ConfirmDialog ref="confirmDialogue" />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">จัดการแพ็กเกจ</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col ref="form" cols="12">
        <FormPackage
          v-if="isAddPackage"
          class="py-4 px-6"
          @input="onAddPackage($event)"
        />
        <FormPackage
          v-else
          class="py-4 px-6"
          type="edit"
          :form="form"
          @changeType="isAddPackage = !isAddPackage"
          @input="onEditPackage($event)"
        />
      </v-col>
      <v-container>
        <v-card>
          <v-row>
            <v-col cols="12" md="6"></v-col>
            <v-col cols="12" md="6">
              <v-select
                class="mr-4"
                label="สาขา"
                outlined
                :items="branchItems"
                item-text="text"
                item-value="value"
                v-model="form.branch"
                @change="getAllPackage()"
              ></v-select>
            </v-col>
          </v-row>
          <v-col cols="12">
            <v-text-field
              label="ค้นหา"
              hint="กด ENTER เพื่อค้นหา"
              append-icon="mdi-magnify"
              v-model="pagination.search"
              @keydown.enter="getAllPackage()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div v-if="packages.length > 0">
              <v-row>
                <v-col
                  v-for="item in packages"
                  :key="item.packageId"
                  cols="12"
                  md="4"
                >
                  <CardPackage
                    class="mb-6 py-2"
                    :color="item.color"
                    :branch="item.branch"
                    :packageId="item.packageId"
                    :title="item.title"
                    :description="item.description"
                    :price="item.price"
                    :expired="item.expired"
                    :minPrice="item.minPrice"
                    @edit="showDataPackage(item)"
                    @delete="onDeletePackage($event)"
                  />
                </v-col>
              </v-row>
            </div>
            <div class="d-flex justify-center mb-6" v-else>ไม่พบข้อมูล</div>
            <div class="d-flex justify-end mb-5">
              <v-pagination
                v-if="packages.length > 0"
                class="mb-6"
                color="#369CE0"
                v-model="pagination.page"
                :length="pagination.totalPages"
                @input="getAllPackage()"
              ></v-pagination>
            </div>
          </v-col>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import CardPackage from "@/components/Package/CardPackageAdmin.vue";
import FormPackage from "@/components/Package/FormPackage.vue";
import ConfirmDialog from "@/components/Confirm.vue";
import Loading from "@/components/Loading.vue";
import axios from "../../utils/axios.js";
export default {
  components: {
    CardPackage,
    FormPackage,
    Loading,
    ConfirmDialog,
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  data() {
    return {
      loading: false,
      isAddPackage: true,
      packages: [],
      form: {
        packageId: "",
        title: "",
        description: "",
        branch: "",
        price: "",
        color: "",
        items: "",
        expired: "",
      },
      pagination: {
        page: 1,
        totalPages: 1,
        totalDocs: 0,
        limit: 6,
        search: "",
      },
    };
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.form.branch = branch;
    this.getAllPackage();
  },
  methods: {
    async getAllPackage() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios.get(
          `/package?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}&branch=${this.form.branch}`
        );
        this.pagination.page = data.page;
        this.pagination.totalPages = data.totalPages;
        this.packages = data.docs;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onAddPackage(event) {
      try {
        await axios.post(`/package`, event);
        this.pagination.page = 0;
        this.getAllPackage();
      } catch (error) {
        console.error(error);
      }
    },
    async onEditPackage(event) {
      try {
        const packageId = this.form.packageId;
        delete this.form.packageId;
        await axios.patch(`/package/${packageId}`, event);
        this.isAddPackage = true;
        this.pagination.page = 0;
        this.getAllPackage();
      } catch (error) {
        console.error(error);
      }
    },
    async onDeletePackage(event) {
      try {
        const confirm = await this.$refs.confirmDialogue.show();
        if (confirm) {
          await axios.delete(`/package/${event}`);
          this.pagination.page = 0;
          this.getAllPackage();
        }
      } catch (error) {
        console.error(error);
      }
    },
    showDataPackage(event) {
      this.form = event;
      this.isAddPackage = true;
      this.$nextTick(() => {
        this.isAddPackage = false;
      });
      const el = this.$refs.form;
      setTimeout(() => {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 200);
    },
  },
};
</script>
