const state = {
  info: {
    profile: {
      id: null,
      firstname: "",
      lastname: "",
    },
    token: "",
  },
};

const actions = {
  setInfo({ commit }, payload) {
    commit("SET_INFO", payload);
  },
  clearInfo({ commit }) {
    commit("CLEAR_INFO");
  },
};

const mutations = {
  SET_INFO(state, payload) {
    state.info = {
      ...payload,
    };
  },
  CLEAR_INFO() {
    state.info = {
      profile: {
        id: null,
        firstname: "",
        lastname: "",
      },
      token: "",
    };
  },
};

const getters = {
  info: (state) => state.info,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
