<template>
  <v-container :class="classObject">
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">สร้างรายการจอง</h2>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <calendar-schedule @saveDate="openDialog($event)" />
      <v-dialog v-model="isSchedule" max-width="500">
        <v-card outlined>
          <v-card-title class="header justify-center mb-4">
            <span class="font-weight-bold">{{ formatDate(date) }}</span>
          </v-card-title>
          <v-container>
            <div
              v-for="(item, index) in items"
              :key="index"
              class="header px-4 mb-6"
            >
              <v-row>
                <v-col class="mt-1" cols="6">
                  {{ formatSchedule(item.startTime, item.endTime) }}
                </v-col>
                <v-col cols="6">
                  <v-btn
                    v-if="item.slot >= item.max"
                    width="140"
                    dark
                    depressed
                    color="#af5a5f"
                  >
                    เต็ม
                  </v-btn>
                  <v-btn
                    v-else
                    width="140"
                    dark
                    depressed
                    color="#23b574"
                    @click="
                      setTime(item.scheduleId, item.startTime, item.endTime)
                    "
                  >
                    ว่าง {{ item.max - item.slot }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-btn
                color="#23b574"
                width="100%"
                dark
                depressed
                @click="isSchedule = false"
              >
                ยืนยัน
              </v-btn>
            </div>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row v-if="startTime && endTime">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title> สรุปการจอง </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="5"> จำนวนผู้เข้าร่วม </v-col>
                <v-col class="pt-0" cols="5">
                  <v-text-field
                    v-model.number="participants"
                    :onkeypress="onkeypress"
                    :rules="participantRules"
                    class="py-0 my-0"
                    type="number"
                    min="0"
                    required
                  />
                </v-col>
                <v-col class="2"> คน </v-col>
              </v-row>
              <v-row>
                <v-col cols="5"> สาขา </v-col>
                <v-col class="pt-0" cols="5">
                  <v-select
                    :items="['ตรัง', 'สาธร']"
                    v-model="branch"
                    :rules="notEmpytyRules"
                    class="py-0 my-0"
                    type="number"
                    min="0"
                    required
                  />
                </v-col>
                <v-col class="2"></v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> วันที่ </v-col>
                <v-col cols="9">
                  {{ formatDate(date) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> เวลา </v-col>
                <v-col cols="9">
                  <span v-if="startTime && endTime">
                    {{ formatSchedule(startTime, endTime) }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> ชื่อ</v-col>
                <v-col cols="9">
                  <v-autocomplete
                    class="mt-0 pt-0"
                    no-data-text="ไม่พบข้อมูล"
                    auto-select-first
                    placeholder="ชื่อ"
                    @change="getMemberById($event)"
                    :rules="nameRules"
                    :items="members"
                    item-text="name"
                    v-model="form"
                    return-object
                    dense
                    required
                  ></v-autocomplete>
                  <!-- {{ profile.firstname }} {{ profile.lastname }} -->
                </v-col>
              </v-row>
              <!-- <v-row>
              <v-col cols="3"> สถานะ </v-col>
              <v-col cols="9"> รอคอนเฟิร์ม </v-col>
            </v-row> -->
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="startTime && endTime">
      <v-col cols="12">
        <Participant-Add
          v-if="participants"
          :member="profile"
          :children="children"
          :participants="participants"
          @input="onAddOrder($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import date from "../../../utils/formatDate";
import calendarSchedule from "../components/calendar-schedule/calendar-schedule.vue";
import ParticipantAdd from "../components/ParticipantAdd.vue";
// import ScheduleTable from "../components/schedule-table.vue";
export default {
  components: { calendarSchedule, ParticipantAdd },
  data() {
    return {
      loading: false,
      isParticipantAdd: false,
      date: "",
      now: dayjs().format("YYYY-MM-DD"),
      isSchedule: false,
      startTime: "",
      endTime: "",
      children: [],
      profile: {},
      branch: "ตรัง",
      form: {
        branch: "",
        scheduleId: "",
        memberId: "",
        participant: null,
      },
      participants: 1,
      breadItem: [
        {
          text: "สร้างรายการจอง",
          disabled: true,
        },
      ],
      items: [],
      members: [],
      onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
      notEmpytyRules: [(v) => !!v || "กรุณากรอกข้อมูล"],
      participantRules: [
        (v) => !!v || "กรุณากรอกจำนวนผู้เข้าร่วม",
        (v) => v > 0 || "จำนวนผู้เข้าร่วม 1 คน",
      ],
      nameRules: [(v) => !!v || "กรุณากรอกข้อมูล"],
    };
  },
  mounted() {
    this.getAllMember();
  },
  methods: {
    openDialog(date) {
      this.date = date;
      this.getSchedule(date);
      this.isSchedule = true;
    },
    async getAllMember() {
      this.loading = true;
      try {
        const {
          data: { data },
        } = await axios.get(`${process.env.VUE_APP_API_URI}/member/all`);
        data.map((member) => {
          this.members.push({
            memberId: member.memberId,
            name: `${member.firstname} ${member.lastname}`,
          });
        });

        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getMemberById() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios.get(
          `${process.env.VUE_APP_API_URI}/member/${this.form.memberId}`
        );
        this.profile = data.member;
        this.children = data.children;
        this.isParticipantAdd = true;
      } catch (error) {
        console.error(error);
      }
    },
    async getSchedule(event) {
      try {
        this.isLoading = true;
        const {
          data: { data },
        } = await axios.get(
          `${process.env.VUE_APP_API_URI}/schedule/date/search?date=${event}`
        );
        this.items = data;
        this.isSchedule = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setTime(scheduleId, startTime, endTime) {
      this.form.scheduleId = scheduleId;
      this.startTime = startTime;
      this.endTime = endTime;
      this.isSchedule = false;
    },
    async onAddOrder(event) {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          this.isLoading = true;
          this.form.participant = event;
          this.form.branch = this.branch;
          await axios.post(`${process.env.VUE_APP_API_URI}/order`, this.form);

          this.alertSuccess("ดำเนินการสำเร็จ");
        } catch (error) {
          this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
        } finally {
          this.isLoading = false;
        }
      } else {
        this.alertWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertWarning(text) {
      this.$toast.warning(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    formatDate(d) {
      return date.default(d);
    },
  },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
  },
};
</script>

<style></style>
