<template>
  <div class="text-center">
    <v-dialog
      persistent
      transition="dialog-top-transition"
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="primary lighten-2 white--text" dark>
          ยืนยันการชำระเงิน
        </v-card-title>
        <v-container>
          <v-row class="my-0 py-0">
            <v-col class="my-0 py-0" cols="12" md="6">
              <v-text-field
                class="my-0 py-0"
                outlined
                v-model="form.price"
                label="ราคารวม"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="6">
              <v-text-field
                class="my-0 py-0"
                v-model="form.promotion"
                outlined
                disabled
                label="ส่วนลดโปรโมชั่น (%)"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            <v-col class="my-0 py-0" cols="12" md="6">
              <v-select
                class="my-0 py-0"
                outlined
                label="แพ็กเกจ"
                :items="packagMemberItems"
                item-text="text"
                item-value="value"
                no-data-text="ไม่พบแพ็กเกจ"
                @change="selectPackage($event)"
              ></v-select>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="6">
              <v-text-field
                v-model="form.point"
                outlined
                class="my-0 py-0"
                :label="`ใช้พ้อยท์ (${point})`"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>
        <v-container>
          <div class="mb-2">ส่วนลด: {{ form.price - total }}</div>
          <div>รวมทั้งสิ้น {{ total }}</div>
        </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('cancel')">ยกเลิก</v-btn>
          <v-btn color="primary" text @click="onSubmit()">ยืนยัน</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    promotion: {
      type: Number,
      default: 0,
    },
    priceAdult: {
      type: [Number, String],
      default: 0,
    },
    packageMember: {
      type: Array,
      default: () => [],
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    point: {
      type: [Number, String],
      default: 0,
    },
    pointToMoney: {
      type: Array,
      default: () => [],
    },
    moneyToPoint: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    packagMemberItems() {
      if (this.packageMember.length > 0) {
        const packagesFilter = this.packageMember.filter((item) => {
          const minute = dayjs(item.package.expired).diff(dayjs(), "minute");
          return item.package.items > 0 && minute > 0;
        });
        const arr = [{ text: "-", value: null }];
        for (let i = 0; i < packagesFilter.length; i++) {
          arr.push({
            text: packagesFilter[i].package.title,
            value: packagesFilter[i].memberPackageId,
          });
        }
        return arr;
      }
      return [];
    },
    discount() {
      if (this.form.point) {
        const closest = this.pointToMoney.reduce((prev, curr) => {
          const a =
            Math.abs(curr.point - this.form.point) <
              Math.abs(prev.point - this.form.point) &&
            curr.point <= this.form.point
              ? curr
              : prev;
          return a;
        });
        if (this.form.point < closest.point) {
          return { money: 0, point: 0 };
        }
        if (this.form.point > this.point) {
          return { money: 0, point: 0 };
        }
        return { money: closest.money, point: closest.point };
      }
      return { money: 0, point: 0 };
    },
    discountPackage() {
      if (this.packages.minPrice >= this.form.price) {
        return this.packages.minPrice;
      }
      return 0;
    },
    totalDiscount() {
      return this.form.price - this.total;
    },
    total() {
      let priceTotal = this.form.price;
      if (this.order.branch === "sathorn") {
        const youngAndStudent =
          this.order.price.countYoung + this.order.price.countStudent;
        const countAudult2 = this.order.price.countAdult - youngAndStudent;
        if (countAudult2 <= 0) {
          const seinorSubCountAudult =
            this.order.price.countSenior - Math.abs(countAudult2);
          if (seinorSubCountAudult <= 0) {
            priceTotal =
              this.order.price.countYoung * this.order.price.priceYoung +
              this.order.price.countStudent * this.order.price.priceStudent;
          }
          if (seinorSubCountAudult > 0) {
            priceTotal =
              this.order.price.countYoung * this.order.price.priceYoung +
              this.order.price.countStudent * this.order.price.priceStudent +
              seinorSubCountAudult * this.order.price.priceSenior;
          }
        }
        if (countAudult2 > 0) {
          const price = this.order.price.priceAdult * Math.abs(countAudult2);
          const seniorPrice =
            this.order.price.priceSenior * this.order.price.countSenior;
          priceTotal =
            price +
            seniorPrice +
            this.order.price.countYoung * this.order.price.priceYoung +
            this.order.price.countStudent * this.order.price.priceStudent;
        }
      }
      if (this.discount) {
        const t = priceTotal - this.discountPackage;
        const total = t - this.discount.money;
        const a = 100 - this.form.promotion;
        const result = (total * a) / 100;
        if (result < 0) return 0;
        return result;
      }
      return priceTotal;
    },
  },
  mounted() {
    setTimeout(() => {
      this.form.price = this.price;
      this.form.promotion = this.promotion;
      this.form.pointToMoney = this.pointToMoney;
      this.form.moneyToPoint = this.moneyToPoint;
    }, 1600);
  },
  methods: {
    async onSubmit() {
      try {
        const payload = {
          total: this.total,
          discount: this.totalDiscount,
          point: this.discount.point,
          memberPackageId: this.packages.memberPackageId,
        };
        this.$emit("submit", payload);
        this.dialog = false;
      } catch (error) {
        console.error(error);
      }
    },
    selectPackage(event) {
      const arr = this.packageMember.filter(
        (item) => item.memberPackageId === event
      );
      if (arr.length > 0) {
        this.packages.memberPackageId = arr[0].memberPackageId;
        this.packages.title = arr[0].package.title;
        this.packages.quatity = arr[0].package.items;
        this.packages.expired = arr[0].package.expired;
        this.packages.minPrice = arr[0].package.minPrice;
      } else {
        this.packages.minPrice = 0;
      }
    },
  },
  data() {
    return {
      dialog: true,
      packages: {
        memberPackageId: 0,
        title: "",
        quatity: 0,
        expired: "",
        minPrice: 0,
      },
      form: {
        price: this.price,
        point: 0,
        total: this.total,
        discount: this.totalDiscount,
        promotion: this.promotion,
        pointToMoney: this.pointToMoney,
        moneyToPoint: this.moneyToPoint,
      },
    };
  },
};
</script>
