<template>
  <v-card
    @click="onChoosePackage()"
    v-bind="attrs"
    v-on="{ ...$listeners }"
    dark
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <slot>
        <div>
          <v-card-title class="text-h4" v-text="title"></v-card-title>
          <v-card-subtitle v-text="description"></v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2"
            >สาขา: {{ branch | formatBranch }}</v-card-subtitle
          >
          <v-card-subtitle class="py-0 mb-2"
            >ราคา: {{ formatCurrency(price) }}</v-card-subtitle
          >
          <v-card-subtitle class="py-0 mb-2"
            >จำนวน: {{ items }}</v-card-subtitle
          >
          <v-card-subtitle class="py-0 mb-2"
            >มูลค่าต่อรอบ: {{ formatCurrency(minPrice) }}</v-card-subtitle
          >
          <v-card-subtitle class="py-0"
            >จำนวนวันหมดอายุ: {{ expired }}
          </v-card-subtitle>
          <v-card-actions>
            <div v-if="type == 'add'">
              <v-btn class="ml-2 mt-5" outlined rounded @click="onEdit()">
                เลือก
              </v-btn>
            </div>
            <div v-else>
              <v-btn class="ml-2 mt-5" outlined rounded @click="onEdit()">
                <v-icon>mdi-pencil</v-icon> แก้ไข
              </v-btn>
              <v-btn class="ml-2 mt-5" outlined rounded @click="onDelete()">
                <v-icon>mdi-delete</v-icon> ลบ
              </v-btn>
            </div>
          </v-card-actions>
        </div>
      </slot>

      <v-avatar
        v-if="$vuetify.breakpoint.width >= 410"
        class="ma-3"
        size="125"
        tile
      >
        <v-img src="/svg/PlanToys_Logo-01.svg"></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: "",
    },
    branch: {
      type: String,
      default: "",
    },
    packageId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    expired: {
      type: Number,
      default: 0,
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    items: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
  },
  data: () => ({}),
  methods: {
    async onChoosePackage() {
      this.$emit("choose-package", this.packageId);
    },
    onEdit() {
      const payload = {
        title: this.title,
        description: this.description,
        expired: this.expired,
        price: this.price,
        image: this.image,
      };
      this.$emit("edit", payload);
    },
    onDelete() {
      // console.log(this.packageId);
      this.$emit("delete", this.packageId);
    },
  },
};
</script>
