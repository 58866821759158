import axios from "axios";
// import store from "../store";

let token = null;
const vuex = JSON.parse(localStorage.getItem("vuex"));

// if (store?.state?.User?.info?.token) {
//   token = "Bearer " + store.state.User.info.token;
// }

if (vuex?.User?.info?.token) {
  token = "Bearer " + vuex?.User?.info?.token;
}

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_API_URI}`,
  timeout: 3000,
  headers: {
    "X-Custom-Header": "foobar",
    Authorization: token,
  },
});

export default instance;
