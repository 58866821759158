<template>
  <v-container :class="classObject">
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">รายชื่อสมาชิก</h2>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end py-0" cols="12" sm="6" lg="5">
        <v-btn
          color="#8bcef5"
          depressed
          width="13rem"
          class="white--text"
          link
          to="/member/add"
        >
          <v-icon left> mdi-plus </v-icon>
          เพิ่มสมาชิก
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="start" class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-text-field
          label="ค้นหา"
          append-icon="mdi-magnify"
          v-model="search"
          hint="กด Enter เพื่อค้นหา"
          @keydown.enter="onSearch()"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="members"
          class="elevation-0"
          item-key="memberId"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalDocs"
          @update:options="getMember"
          hide-default-footer
          @click:row="onClickDetail"
        >
          <template #loading> กำลังโหลดข้อมูล </template>
          <template #no-data> ไม่พบข้อมูล </template>
          <template v-slot:[`item.action`]>
            <a style="text-decoration: underline">รายละเอียด</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="end" class="my-5">
      <v-pagination
        v-model="options.page"
        :length="totalPages"
        :total-visible="7"
        color="#3CB2FE"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="onChangePage($event)"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      search: "",
      members: [],
      totalDocs: 0,
      totalPages: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ["memberId"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "ชื่อ",
          value: "firstname",
        },
        {
          text: "นามสกุล",
          value: "lastname",
        },
        {
          text: "หมายเลขโทรศัพท์",
          value: "tel",
        },
        {
          text: "จำนวนสมาชิก",
          value: "childrenQty",
        },
        {
          text: "ดำเนินการ",
          value: "action",
        },
      ],
      breadItem: [
        {
          text: "รายชื่อสมาชิก",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async getMember() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URI}/member/?search=${this.search}&page=${this.options.page}&sortBy=${this.options.sortBy[0]}&sort=${this.sortDescValue}&limit=${this.options.itemsPerPage}`,
        });
        this.members = data.data.docs;
        this.totalDocs = data.data.totalDocs;
        this.totalPages = data.data.totalPages;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async onSearch() {
      try {
        this.options.page = 1;
        this.$router.replace(
          `/member?page=${this.options.page}&search=${this.search}`
        );
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },
    onClickDetail(item) {
      this.$router.push(`/member/detail/${item.memberId}`);
    },
    onChangePage(event) {
      this.$router.replace(`/member?page=${event}&search=${this.search}`);
      this.$router.go();
    },
  },
  mounted() {
    this.search = this.$route.query.search || "";
    this.options.page = parseInt(this.$route.query.page) || 1;
    this.getMember();
  },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    sortDescValue() {
      if (this.options.sortDesc[0] === true) {
        return 1;
      } else {
        return -1;
      }
    },
  },
};
</script>

<style scoped>
::v-deep label.v-label.theme--light {
  color: rgba(0, 0, 0, 0.38) !important;
}
::v-deep td {
  color: rgba(0, 0, 0, 0.6) !important;
  cursor: pointer;
}
</style>
