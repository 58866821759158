<template>
  <v-dialog v-model="dialog" width="1200">
    <ConfirmDialog
      message="ต้องการดำเนินการต่อหรือไม่ ?"
      ref="confirmDialogue"
    />
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn
          block
          height="16rem"
          color="#369CE0"
          class="opacity-08 d-flex flex-column justify-space-between"
          :class="hover ? 'opacity-1 cursor-pointer' : ''"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <div></div>
          <div class="mx-auto">
            <v-icon size="8rem">mdi-plus</v-icon>
          </div>
          <div></div>
        </v-btn>
      </v-hover>
    </template>
    <v-card class="py-6">
      <v-card-title>เลือกแพ็กเกจที่จะเพิ่ม</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" md="6"></v-col>
          <v-col cols="12" md="6">
            <v-select
              label="สาขา"
              outlined
              :items="branchItems"
              item-text="text"
              item-value="value"
              v-model="branch"
              @change="changeBranch($event)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              class="mb-4"
              label="ค้นหา"
              append-icon="mdi-magnify"
              hint="กด Enter เพื่อค้นหา"
              @keydown.enter="onSearch()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in packages.docs"
            :key="index"
            cols="12"
            md="4"
          >
            <CardPackage
              type="add"
              :branch="item.branch"
              :items="item.items"
              :packageId="item.packageId"
              :title="item.title"
              :description="item.description"
              :price="item.price"
              :expired="item.expired"
              :minPrice="item.minPrice"
              :color="item.color"
              @choose-package="onChoosePackage($event)"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-8">
          <v-pagination
            v-model="packages.page"
            color="#369CE0"
            :length="packages.totalPages"
            @input="paginationPage($event)"
          ></v-pagination>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import CardPackage from "@/components/Package/CardPackage.vue";
import ConfirmDialog from "@/components/Confirm.vue";

export default {
  components: {
    CardPackage,
    ConfirmDialog,
  },
  props: {
    packages: {
      type: [Object, Array],
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    packageId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    expired: {
      type: Number,
      default: 0,
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    attrs() {
      return {
        ...this.$attrs,
      };
    },
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  data: () => ({
    dialog: false,
    search: "",
    branch: "",
    pagination: {
      page: 1,
    },
  }),
  methods: {
    async onChoosePackage(packageId) {
      const confirm = await this.$refs.confirmDialogue.show();
      if (confirm) {
        this.$emit("choose-package", packageId);
        this.dialog = false;
      }
    },
    onSearch() {
      this.$emit("search", this.search);
    },
    paginationPage(event) {
      this.$emit("pagination-page", event);
    },
    onEdit() {
      const payload = {
        title: this.title,
        description: this.description,
        expired: this.expired,
        price: this.price,
        image: this.image,
      };
      this.$emit("edit", payload);
    },
    onDelete() {
      // console.log(this.packageId);
      this.$emit("delete", this.packageId);
    },
    changeBranch() {
      this.$emit("change-branch", this.branch);
    },
  },
};
</script>
<style scoped>
.opacity-08 {
  opacity: 0.8;
}
.opacity-1 {
  opacity: 1;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
