import Vue from "vue";

Vue.filter("formatStatus", (val) => {
  switch (val) {
    case "waiting":
      return "รอคอนเฟิร์ม";
    case "pending":
      return "รอชำระเงิน";
    case "paid":
      return "ชำระเงินแล้ว";
    case "confirmed":
      return "คอนเฟิร์ม";
    case "canceled":
      return "ยกเลิก";
    case "rejected":
      return "ปฏิเสธ";
    default:
      return "";
  }
});

Vue.filter("formatBranch", (val) => {
  switch (val) {
    case "trang":
      return "ตรัง";
    case "sathorn":
      return "สาทร";
    default:
      return "ทั้งหมด";
  }
});

Vue.filter("bookingType", (val) => {
  switch (val) {
    case "normal":
      return "ปกติ";
    case "group":
      return "กลุ่ม";
    case "event":
      return "กิจกรรม";
    default:
      return val;
  }
});

Vue.filter("formatStatusTime", (val) => {
  switch (val) {
    case "morning":
      return "เช้า";
    case "afternoon":
      return "บ่าย";
    case "evening":
      return "เบ็ย";
    case "allday":
      return "ทั้งวัน";
    default:
      return val;
  }
});

Vue.filter("formatGender", (val) => {
  switch (val) {
    case "male":
      return "ชาย";
    case "female":
      return "หญิง";
    default:
      return "ไม่ระบุ";
  }
});
