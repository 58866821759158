import Vue from "vue";
import VueRouter from "vue-router";
import jwt from "jsonwebtoken";
import Home from "@/views/Home.vue";
import MemberManagement from "@/views/Member/pages/Member-management.vue";
import MemberDetail from "@/views/Member/pages/Member-detail.vue";
import MemberAdd from "@/views/Member/pages/Member-add.vue";

import OrderList from "@/views/Order/pages/Order-list.vue";
import OrderDetail from "@/views/Order/pages/Order-detail.vue";
import OrderGroupDetail from "@/views/Order/pages/group/_slug.vue";
import Package from "@/views/Package/index.vue";
import NotFound from "@/views/NotFound.vue";

import ScheduleManagement from "@/views/Schedule/pages/Schedule-management.vue";

// import LIFFLogin from "@/views/LIFF/pages/LIFF-Login.vue";
// import Register from "@/views/LIFF/pages/Register.vue";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEach: () => {},
  },
  {
    path: "/member",
    name: "Member-Management",
    component: MemberManagement,
    meta: { requiresAuth: true },
  },
  {
    path: "/member/add",
    name: "Member-Add",
    component: MemberAdd,
    meta: { requiresAuth: true },
  },
  {
    path: "/member/detail/:memberId",
    name: "Member-Detail",
    meta: { requiresAuth: true },
    component: MemberDetail,
  },
  {
    path: "/order",
    name: "Order-List",
    meta: { requiresAuth: true },
    component: OrderList,
  },
  {
    path: "/package",
    name: "Package",
    meta: { requiresAuth: true },
    component: Package,
  },
  {
    path: "/transactions",
    name: "Transactions",
    meta: { requiresAuth: true },
    component: () => import("@/views/Transactions/index.vue"),
  },
  {
    path: "/setting",
    name: "Setting",
    meta: { requiresAuth: true },
    component: () => import("@/views/Setting/index.vue"),
  },
  {
    path: "/order/detail/:orderId",
    name: "Order-Detail",
    meta: { requiresAuth: true },
    component: OrderDetail,
  },
  {
    path: "/create-order",
    name: "Create-Order",
    meta: { requiresAuth: true },
    component: () => import("@/views/Create-Order/index.vue"),
  },
  {
    path: "/order/group/:orderId",
    name: "Order-Group-Detail",
    meta: { requiresAuth: true },
    component: OrderGroupDetail,
  },
  {
    path: "/schedule",
    name: "Schedule-Management",
    meta: { requiresAuth: true },
    component: ScheduleManagement,
  },
  {
    path: "/booking",
    name: "Book-Management",
    meta: { requiresAuth: true },
    component: () => import("../views/Book/pages/Book-management.vue"),
  },
  {
    path: "/ticket",
    name: "ticket",
    meta: { requiresAuth: true },
    component: () => import("../views/Ticket/index.vue"),
  },
  {
    path: "/ticket/schedule/:slug",
    name: "ticket",
    meta: { requiresAuth: true },
    component: () => import("../views/Ticket/schedule/_slug.vue"),
  },
  {
    path: "/history-schedule",
    name: "History-Schedule",
    meta: { requiresAuth: true },
    component: () => import("../views/Schedule/pages/Schedule-History.vue"),
  },
  {
    path: "/report",
    name: "Report",
    meta: { requiresAuth: true },
    component: () => import("../views/Report/Report.vue"),
  },
  {
    path: "/report-personalized",
    name: "Report-Personalized",
    meta: { requiresAuth: true },
    component: () => import("../views/Report/Report-Personalized.vue"),
  },
  {
    path: "/report-graph",
    name: "Report-Graph",
    meta: { requiresAuth: true },
    component: () => import("../views/Report/Report-Graph.vue"),
  },
  {
    path: "/logs",
    name: "Logs",
    meta: { requiresAuth: true },
    component: () => import("../views/Logs/index.vue"),
  },
  {
    path: "/management-admin",
    name: "ManagementAdmin",
    meta: { requiresAuth: true },
    component: () => import("../views/Management-Admin/index.vue"),
  },
  {
    path: "/management-admin/edit/:slug",
    name: "ManagementAdminSlug",
    meta: { requiresAuth: true },
    component: () => import("../views/Management-Admin/edit/_slug.vue"),
  },
  {
    path: "/management-admin/add",
    name: "ManagementAddAdmin",
    meta: { requiresAuth: true },
    component: () => import("../views/Management-Admin/add/index.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: () => import("../views/profile/index.vue"),
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    jwt.verify(
      store.state.User.info.token,
      "jx0hYKKvO0ajJm9WWfpQFFvy2MQo1BNrOwOfES7xc",
      (err) => {
        if (err) {
          return next("/");
        }
      }
    );
    next();
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
