import Vue from "vue";

const MyPlugin = {
  install(Vue) {
    Vue.prototype.alertSuccess = (text) => {
      Vue.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    };
  },
};

Vue.use(MyPlugin);
