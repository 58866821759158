<template>
  <v-expansion-panel>
    <Loading v-if="loading" />
    <v-expansion-panel-header color="#8bcef5" class="py-5">
      <h3 class="white--text">รายการจอง</h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="orders"
          :loading="loading"
          @click:row="
            $event.type === 'group'
              ? $router.push(`/order/group/${$event.orderId}`)
              : $router.push(`/order/detail/${$event.orderId}`)
          "
        >
          <template #no-data> ไม่พบข้อมูล </template>
          <template v-slot:[`item.orderId`]="{ item }">
            <a style="text-decoration: underline">{{ item.orderId }}</a>
          </template>
          <template v-slot:[`item.schedule.date`]="{ item }">
            {{ item.schedule.date | formatMiniDate }}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
          </template>
        </v-data-table>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";
export default {
  name: "ExpansionPanelMemberOrderHistory",
  components: {
    Loading,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Booking ID",
          value: "orderId",
          sortable: false,
        },
        {
          text: "วันที่",
          value: "schedule.date",
          sortable: false,
        },
        {
          text: "เวลา",
          value: "time",
          sortable: false,
        },
        {
          text: "หมายเหตุ",
          value: "note",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async getAllOrderByMember() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URI}/order/member/${this.$route.params.memberId}`,
        });

        this.orders = data.data;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
