<template>
  <v-col>
    <v-sheet height="64" color="blue lighten-1">
      <v-row class="pt-1">
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-btn
            tile
            color="white"
            min-width="1rem"
            height="1.2rem"
            depressed
            class="px-0 mr-1"
            @click="$refs.calendar.prev()"
          >
            <v-icon color="black">mdi-chevron-left</v-icon>
          </v-btn>
          <span
            v-if="$refs.calendar"
            class="white--text"
            style="font-size: 1.5rem"
          >
            {{ $refs.calendar.title }}
          </span>
          <v-btn
            tile
            color="white"
            min-width="1rem"
            height="1.2rem"
            depressed
            class="px-0 ml-1"
            @click="$refs.calendar.next()"
          >
            <v-icon color="black">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="focus"
        @click:date="testClick"
        locale="th"
      >
      </v-calendar>
    </v-sheet>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      focus: "",
    };
  },
  mounted() {
    this.focus = this.$refs.calendar.now;
  },
  methods: {
    testClick() {
      this.$emit("saveDate", this.focus);
    },
  },
};
</script>

<style scoped>
::v-deep .v-calendar-weekly__head {
  height: 2rem;
}
::v-deep .v-calendar-weekly__head-weekday {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
