<template>
  <v-expansion-panel>
    <Loading v-if="loading" />
    <v-expansion-panel-header color="#8bcef5" class="py-5">
      <h3 class="white--text">
        <v-icon dark left>mdi-account</v-icon>ข้อมูลส่วนตัว
      </h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ชื่อ"
                :rules="notEmpytyRules"
                v-model="memberEdit.firstname"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="notEmpytyRules"
                label="นามสกุล"
                v-model="memberEdit.lastname"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ชื่อเล่น"
                v-model="memberEdit.nickname"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                :rules="notEmpytyRules"
                v-model="memberEdit.sex"
                :items="sexItems"
                item-value="value"
                item-text="text"
                label="เพศ *"
                :disabled="!edit"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <member-edit-date-picker
                @saveDate="memberEdit.birthday = $event"
                :dateProps="dateFormat"
                v-if="edit"
              />
              <v-text-field
                label="วันเกิด"
                v-model="dateShow"
                :disabled="!edit"
                v-else
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="อายุ"
                disabled
                :value="memberEdit.birthday | formatAge"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :rules="notEmpytyRules"
                label="หมายเลขโทรศัพท์"
                v-model="memberEdit.tel"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="อีเมล"
                v-model="memberEdit.email"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ทะเบียนรถ"
                v-model="memberEdit.carLicensePlate"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="FACEBOOK ID"
                v-model="memberEdit.facebookId"
                disabled
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="LINE UID"
                v-model="memberEdit.lineUid"
                disabled
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="LINE ID"
                v-model="memberEdit.lineId"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="memberEdit.nationality"
                item-text="text"
                item-value="value"
                :items="nationalityList"
                :disabled="!edit"
                placeholder="สัญชาติ"
                auto-select-first
              >
                <template #no-data>
                  <span>ไม่พบข้อมูล</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <AddressField
                :rules="notEmpytyRules"
                v-model="memberEdit.address.subDistrict"
                label="ตำบล"
                type="sub-district"
                :disabled="!edit"
                @select="onSelectAddress($event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <AddressField
                :rules="notEmpytyRules"
                v-model="memberEdit.address.district"
                label="อำเภอ"
                type="district"
                :disabled="!edit"
                @select="onSelectAddress($event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <AddressField
                :rules="notEmpytyRules"
                v-model="memberEdit.address.province"
                label="จังหวัด"
                type="province"
                :disabled="!edit"
                @select="onSelectAddress($event)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <AddressField
                :rules="notEmpytyRules"
                v-model="memberEdit.address.postcode"
                label="รหัสไปรษณีย์"
                type="postcode"
                :disabled="!edit"
                @select="onSelectAddress($event)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="หมายเหตุ"
                v-model="memberEdit.note"
                :disabled="!edit"
                :loading="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end" v-if="edit">
            <v-col cols="6" sm="3">
              <v-btn
                class="white--text"
                color="red lighten-2"
                block
                depressed
                @click="cancelEdit"
                >ยกเลิก</v-btn
              >
            </v-col>
            <ConfirmEditButton :memberEditProps="memberEdit" />
          </v-row>
        </v-form>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import AddressField from "@/components/AddressField.vue";
import Loading from "@/components/Loading.vue";
import ConfirmEditButton from "./confirm-edit-button.vue";
import MemberEditDatePicker from "./member-edit/member-edit-date-picker.vue";
import nationalityJson from "../../../../nationality.json";

export default {
  components: {
    ConfirmEditButton,
    MemberEditDatePicker,
    AddressField,
    Loading,
  },
  name: "ExpansionPanelMemberDetail",
  props: {
    editableProps: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      member: {},
      memberEdit: {
        address: {
          province: "",
          district: "",
          subDistrict: "",
          postcode: "",
        },
      },
      dateFormat: null,
      dateShow: null,
    };
  },
  methods: {
    async getMemberOne() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URI}/member/${this.$route.params.memberId}`,
        });

        this.member = data.member;
        this.dateShow = dayjs(data.member.birthday).format("DD MMMM BBBB");
        this.dateFormat = dayjs(data.member.birthday).format("YYYY-MM-DD");
        this.memberEdit = JSON.parse(JSON.stringify(this.member));
        if (this.memberEdit.address.province.text) {
          this.memberEdit.address.province =
            this.memberEdit.address.province.text;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    cancelEdit() {
      this.memberEdit = JSON.parse(JSON.stringify(this.member));
      this.$emit("cancelEdit", false);
    },
    onSelectAddress(address) {
      this.memberEdit.address.subDistrict = address?.subDistrict || "";
      this.memberEdit.address.district = address?.district || "";
      this.memberEdit.address.province = address?.province || "";
      this.memberEdit.address.postcode = address?.postcode || "";
    },
  },
  mounted() {
    this.getMemberOne();
  },
  computed: {
    notEmpytyRules() {
      return [(v) => !!v || "กรุณากรอกข้อมูล"];
    },
    nationalityList() {
      const list = [];
      const nationality = this.memberEdit.nationality;
      let regx = null;
      if (nationality) {
        regx = nationality.match(/^[a-zA-Z]*$/);
      }
      if (regx) {
        nationalityJson.map((item) =>
          list.push({ text: item.name, value: item.enName })
        );
      } else {
        nationalityJson.map((item) =>
          list.push({ text: item.name, value: item.name })
        );
      }
      return list;
    },
    edit() {
      return this.editableProps;
    },
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
  },
};
</script>

<style scoped>
::v-deep div.v-text-field__slot > input[disabled] {
  color: rgba(0, 0, 0, 0.7);
}
</style>
