<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="วันเกิด *"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="birthdayRules"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" @change="save" locale="th"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    date: null,
    menu: false,
    birthdayRules: [(v) => !!v || "กรุณากรอกวันเกิด"],
  }),
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit("saveDate", date);
    },
  },
};
</script>

<style></style>
