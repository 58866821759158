import Vue from "vue";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
dayjs.locale("th");
dayjs.extend(buddhistEra);
const MyPlugin = {
  install(Vue) {
    Vue.prototype.formatCurrency = (val) => {
      if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return val;
    };
    Vue.prototype.formatDate = (val) => {
      if (val === null) "";
      return dayjs(val).format("D MMM BBBB");
    };
    Vue.prototype.formatDateThai = (val) => {
      if (val === null) "";
      return dayjs(val).format("D MMMM BBBB");
    };
    Vue.prototype.formatDateTimeThai = (val) => {
      if (val === null) "";
      return dayjs(val).format("D MMMM BBBB HH:mm");
    };
    Vue.prototype.formatSchedule = (startTime, endTime) => {
      if (startTime === "00:00" && endTime === "23:59") {
        return "Allday";
      }
      return `${startTime} - ${endTime} น.`;
    };

    Vue.prototype.statusColor = (value) => {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    };
  },
};

Vue.use(MyPlugin);
