<template>
  <v-dialog v-model="dialog" width="1400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="#369CE0" dark v-bind="attrs" width="13rem" v-on="on">
        <v-icon left> mdi-card-multiple </v-icon>
        แพ็กเกจ
      </v-btn>
    </template>
    <v-card>
      <div class="d-flex justify-space-between px-4">
        <v-card-title class="my-0 py-0"> จัดการแพ็กเกจ </v-card-title>
      </div>
      <v-row class="px-6">
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4"></v-col>
        <v-col cols="12" md="4">
          <v-select
            outlined
            label="สาขา"
            :items="branchItems"
            item-text="text"
            item-value="value"
            v-model="branch"
            @change="onChangeBranch()"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="12">
          <v-text-field
            v-model="search"
            class="my-0 py-0"
            label="ค้นหา"
            append-icon="mdi-magnify"
            hint="กด ENTER เพื่อค้นหา"
            @keydown.enter="onSearchMemberPackage()"
          ></v-text-field>
        </v-col>
      </v-row>

      <div class="mt-4 px-3">
        <v-row>
          <v-col cols="12" md="4">
            <CardPackageNew
              :packages="packages"
              @pagination-page="paginationPage($event)"
              @choose-package="onChoosePackage($event)"
              @search="onSearch($event)"
              @change-branch="onChangeBranch2($event)"
            />
          </v-col>
          <v-col
            v-for="(item, index) in memberPackage.docs"
            :key="index"
            cols="12"
            md="4"
          >
            <CardPackageMember
              :items="item.package.items"
              :updatedAt="item.updatedAt"
              :branch="item.package.branch"
              :status="item.status"
              :packageId="item.memberPackageId"
              :title="item.package.title"
              :description="item.package.description"
              :price="item.package.price"
              :expired="item.package.expired"
              :color="item.package.color"
              @edit="onEdit($event)"
              @delete="onDelete($event)"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-8">
          <v-pagination
            v-model="memberPackage.page"
            color="#369CE0"
            :length="memberPackage.totalPages"
            @input="paginationPageMemberPackage($event)"
          ></v-pagination>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import CardPackageNew from "@/components/Package/CardPackageNew.vue";
import CardPackageMember from "@/components/Package/CardPackageMember.vue";

export default {
  components: {
    CardPackageNew,
    CardPackageMember,
  },
  props: {
    packages: {
      type: [Object, Array],
      default: () => [],
    },
    memberPackage: {
      type: [Object, Array],
      default: () => [],
    },
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  data() {
    return {
      tab: "",
      dialog: false,
      search: "",
      branch: "",
      pagination: {
        page: 1,
      },
      tabItems: [
        { text: "มีอยู่แล้ว", value: "normal" },
        { text: "เพิ่ม", value: "event" },
      ],
    };
  },
  methods: {
    onChoosePackage(packageId) {
      this.$emit("choose-package", packageId);
    },
    paginationPage(event) {
      this.$emit("pagination-page", event);
    },
    paginationPageMemberPackage(event) {
      this.$emit("pagination-page-member-package", event);
    },
    onChangeBranch() {
      this.$emit("change-branch", this.branch);
    },
    onChangeBranch2(event) {
      this.$emit("change-branch2", event);
    },
    onSearch(event) {
      this.$emit("search", event);
    },
    onSearchMemberPackage() {
      this.$emit("search-member-package", this.search);
    },
    onEdit(event) {
      this.$emit("edit", event);
    },
    onDelete(event) {
      this.$emit("delete", event);
    },
  },
};
</script>
