<template>
  <v-card v-bind="attrs" v-on="{ ...$listeners }" dark>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h4" v-text="title"></v-card-title>
        <v-card-subtitle v-text="description"></v-card-subtitle>
        <v-card-subtitle class="py-0 mb-2"
          >สาขา:
          <span class="font-weight-bold">
            {{ branch | formatBranch }}
          </span></v-card-subtitle
        >
        <v-card-subtitle class="py-0 mb-2"
          >ราคา:
          <span class="font-weight-bold">
            {{ formatCurrency(price) }}
          </span></v-card-subtitle
        >
        <v-card-subtitle class="py-0 mb-2"
          >มูลค่าต่อรอบ:
          <span class="font-weight-bold">{{
            formatCurrency(minPrice)
          }}</span></v-card-subtitle
        >
        <v-card-subtitle class="py-0"
          >จำนวนวันหมดอายุ: <span class="font-weight-bold">{{ expired }}</span>
        </v-card-subtitle>
      </div>

      <v-avatar
        v-if="$vuetify.breakpoint.width >= 410"
        class="ma-3"
        size="125"
        tile
      >
        <v-img src="/svg/PlanToys_Logo-01.svg"></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    packageId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    branch: {
      type: String,
      default: "trang",
    },
    description: {
      type: String,
      default: "",
    },
    expired: {
      type: Number,
      default: 0,
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
  },
  data: () => ({
    items: [
      {
        color: "#1F7087",
        src: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        title: "Supermodel",
        artist: "Foster the People",
      },
      {
        color: "#952175",
        src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
        title: "Halcyon Days",
        artist: "Ellie Goulding",
      },
    ],
  }),
  methods: {
    onEdit() {
      const payload = {
        title: this.title,
        description: this.description,
        expired: this.expired,
        price: this.price,
        image: this.image,
      };
      this.$emit("edit", payload);
    },
    onDelete() {
      // console.log(this.packageId);
      this.$emit("delete", this.packageId);
    },
  },
};
</script>
