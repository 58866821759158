<template>
  <v-row class="mt-5">
    <Loading v-if="loading" />
    <v-col cols="6" sm="3" class="px-2">
      <v-card elevation="0" outlined class="pt-3 pb-5">
        <v-row v-if="orderProps.status === 'waiting'">
          <v-col class="d-flex justify-end pb-0 pt-2 pr-5">
            <v-icon
              v-if="!editStatus"
              color="grey darken-3"
              size="1.3rem"
              @click="editStatus = true"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              @click="saveStatus"
              v-if="editStatus"
              color="green darken-3"
              size="1.3rem"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="editStatus"
              color="red darken-3"
              size="1.3rem"
              @click="editStatus = false"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row>
        <v-card-subtitle class="py-0"> สถานะ </v-card-subtitle>
        <v-card-title class="justify-center" v-if="!editStatus">
          <h3
            v-if="
              orderProps.price &&
              orderProps.price.summary <= 0 &&
              (orderProps.orderStatus == 'paid' ||
                orderProps.orderStatus == 'confirmed')
            "
            :class="statusColor(orderProps.status)"
            class="text-center"
          >
            ยืนยันการจอง
          </h3>
          <h3
            v-else
            :class="statusColor(orderProps.status)"
            class="text-center"
          >
            {{ orderProps.status | formatStatus }}
          </h3>
        </v-card-title>
        <v-card-title class="justify-center" v-else>
          <v-select
            :items="statusSelect"
            item-text="text"
            item-value="value"
            @change="selectStatus($event)"
            v-model="selected"
          >
          </v-select>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="6" sm="3" class="px-2">
      <v-card elevation="0" outlined class="pt-3 pb-5">
        <!-- <v-row>
          <v-col class="d-flex justify-end pb-0 pt-2 pr-5">
            <v-icon
              v-if="!editDate"
              color="grey darken-3"
              size="1.3rem"
              @click="editDate = true"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-if="editDate"
              color="green darken-3"
              size="1.3rem"
              @click="saveDate"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="editDate"
              color="red darken-3"
              size="1.3rem"
              @click="editDate = false"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row> -->
        <v-card-subtitle class="py-0"> วันที่ </v-card-subtitle>
        <v-card-title class="justify-center px-0">
          <h3 class="text-center card-text text-secondary" v-if="!editDate">
            {{ orderProps.schedule.date | formatDateDefault }}
          </h3>
          <v-col cols="9" v-else>
            <DatePicker
              :value="orderProps.schedule.date"
              @input="orderProps.schedule.date = $event"
            />
          </v-col>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="6" sm="3" class="px-2">
      <v-card elevation="0" outlined class="pt-3 pb-5">
        <!-- <v-row>
          <v-col class="d-flex justify-end pb-0 pt-2 pr-5">
            <v-icon
              v-if="!editTime"
              color="grey darken-3"
              size="1.3rem"
              @click="editTime = true"
              >mdi-pencil-outline</v-icon
            >
            <v-icon
              v-if="editTime"
              color="green darken-3"
              size="1.3rem"
              @click="saveTime"
              >mdi-check</v-icon
            >
            <v-icon
              v-if="editTime"
              color="red darken-3"
              size="1.3rem"
              @click="editTime = false"
              >mdi-close</v-icon
            >
          </v-col>
        </v-row> -->
        <v-card-subtitle class="py-0"> เวลา </v-card-subtitle>
        <v-card-title v-if="orderProps.isGroup" class="justify-center px-0">
          <h3 class="text-center card-text text-secondary">
            {{
              formatSchedule(
                orderProps.schedule.startTime,
                orderProps.schedule.endTime
              )
            }}
          </h3>
        </v-card-title>
        <v-card-title v-else class="justify-center px-0">
          <h3 class="text-center card-text text-secondary" v-if="!editTime">
            {{
              formatSchedule(
                orderProps.schedule.startTime,
                orderProps.schedule.endTime
              )
            }}
          </h3>
          <v-col cols="9" v-else>
            <v-text-field
              :value="`${orderProps.schedule.startTime} - ${orderProps.schedule.endTime}`"
              readonly
              @click.stop="getSchedule"
            />
            <v-dialog v-model="isSchedule" max-width="500">
              <v-card>
                <v-card-title class="header justify-center mb-4">
                  <span class="font-weight-bold">{{ date }}</span>
                </v-card-title>
                <v-container>
                  <div
                    v-for="(item, index) in schedules"
                    :key="index"
                    class="header px-4 mb-6"
                  >
                    <v-row>
                      <v-col class="mt-1" cols="6">
                        {{ formatSchedule(item.startTime, item.endTime) }}
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          v-if="item.slot >= item.max"
                          width="140"
                          dark
                          color="#af5a5f"
                        >
                          เต็ม
                        </v-btn>
                        <v-btn
                          v-else
                          width="140"
                          dark
                          color="#23b574"
                          @click="
                            setTime(
                              item.scheduleId,
                              item.startTime,
                              item.endTime
                            )
                          "
                        >
                          ว่าง {{ item.max - item.slot }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div></div>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="6" sm="3" class="px-2">
      <v-card elevation="0" outlined class="pt-3 pb-5">
        <v-card-subtitle class="py-0"> จำนวนผู้เข้าร่วม </v-card-subtitle>
        <v-card-title class="justify-center px-0">
          <h3 class="text-center card-text text-secondary">
            {{
              orderProps.isGroup
                ? orderProps.joinQty
                : orderProps.participant.length
            }}
          </h3>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePicker from "../../../../components/DatePicker.vue";
import Loading from "@/components/Loading.vue";
import axios from "axios";

export default {
  components: { DatePicker, Loading },
  props: {
    orderProps: {
      type: Object,
    },
    branch: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isConfirmActionDialog: false,
      loading: false,
      isSchedule: false,
      editStatus: false,
      editDate: false,
      editTime: false,
      selected: null,
      schedule: {},
      statusSelect: [
        // {
        //   text: "รอคอนเฟิร์ม",
        //   value: "waiting",
        // },
        // {
        //   text: "รอชำระเงิน",
        //   value: "pending",
        // },
        {
          text: "ชำระเงินแล้ว / คอนเฟิร์ม",
          value: "paid",
        },
        {
          text: "ยกเลิก",
          value: "canceled",
        },
        {
          text: "ปฏิเสธ",
          value: "rejected",
        },
      ],
      schedules: [],
    };
  },
  mounted() {
    this.selected = this.orderProps.status;
  },
  methods: {
    statusColor(value) {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
    saveStatus() {
      this.editStatus = false;
      this.$emit("saveStatus", this.selected);
    },
    saveDate() {
      this.editDate = false;
      this.$emit("saveDate", true);
    },
    saveTime() {
      this.editTime = false;
    },
    async getSchedule() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios.get(
          `${process.env.VUE_APP_API_URI}/schedule/date/search?date=${this.orderProps.schedule.date}&branch=${this.branch}`
        );

        this.schedules = data;
        this.isSchedule = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    selectStatus(value) {
      this.$emit("selectStatus", value);
      if (value === "paid") {
        this.editStatus = false;
        this.selected = "";
      }
    },
    async setTime(scheduleId, startTime, endTime) {
      try {
        this.orderProps.schedule.scheduleId = scheduleId;
        this.orderProps.schedule.startTime = startTime;
        this.orderProps.schedule.endTime = endTime;
        this.isSchedule = false;
        const payload = {
          schedule: {
            date: this.orderProps.schedule.date,
            scheduleId,
            startTime,
            endTime,
          },
        };
        await axios.patch(
          `${process.env.VUE_APP_API_URI}/order/${this.orderProps.orderId}`,
          payload
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card-text {
  font-weight: 600;
}
</style>
