<template>
  <v-row>
    <Loading v-if="loading" />
    <v-col>
      <v-form v-model="valid" ref="form">
        <v-expansion-panels multiple accordion tile v-model="panels">
          <v-expansion-panel expand>
            <v-expansion-panel-header color="#8bcef5" class="py-5">
              <h3 class="white--text">ข้อมูลส่วนตัว</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-alert
                  type="error"
                  :value="isUsed"
                  text
                  transition="slide-x-transition"
                >
                  เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว
                </v-alert>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="ชื่อ *"
                      v-model="member.firstname"
                      :loading="loading"
                      :rules="firstnameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="นามสกุล *"
                      v-model="member.lastname"
                      :loading="loading"
                      :rules="lastnameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      :items="sexItems"
                      v-model="member.sex"
                      :rules="sexRules"
                      label="เพศ *"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <member-date-picker @saveDate="saveBirthdayMember" />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="หมายเลขโทรศัพท์ *"
                      v-model="member.tel"
                      :loading="loading"
                      :rules="telRules"
                      maxlength="10"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="อีเมล *"
                      v-model="member.email"
                      :loading="loading"
                      :rules="emailRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="LINE ID"
                      v-model="member.lineId"
                      :loading="loading"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="จำนวนสมาชิก *"
                      type="number"
                      v-model="member.childrenQty"
                      :loading="loading"
                      @input="openPanels"
                      :rules="childrenRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="member.nationality"
                      :items="nationalityList"
                      placeholder="สัญชาติ"
                      :rules="nationalityRules"
                      auto-select-first
                    >
                      <template #no-data>
                        <span>ไม่พบข้อมูล</span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <AddressField
                      :rules="notEmpytyRules"
                      v-model="member.address.subDistrict"
                      label="ตำบล"
                      type="sub-district"
                      @select="onSelectAddress($event)"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <AddressField
                      :rules="notEmpytyRules"
                      v-model="member.address.district"
                      label="อำเภอ"
                      type="district"
                      @select="onSelectAddress($event)"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <AddressField
                      :rules="notEmpytyRules"
                      v-model="member.address.province"
                      label="จังหวัด"
                      type="province"
                      @select="onSelectAddress($event)"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <AddressField
                      :rules="notEmpytyRules"
                      v-model="member.address.postcode"
                      label="รหัสไปรษณีย์"
                      type="postcode"
                      @select="onSelectAddress($event)"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="หมายเหตุ"
                      v-model="member.note"
                      :loading="loading"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
            v-for="(item, i) in Number(member.childrenQty)"
            :key="i"
          >
            <v-expansion-panel-header color="#8bcef5" class="py-5">
              <h3 class="white--text">
                <v-icon dark left>mdi-account</v-icon> สมาชิกคนที่ {{ item }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-form v-model="valid" ref="form">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="ชื่อ *"
                        v-model="allChildren[i].firstname"
                        :loading="loading"
                        v-if="allChildren.length"
                        :rules="firstnameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="นามสกุล *"
                        v-model="allChildren[i].lastname"
                        :loading="loading"
                        v-if="allChildren.length"
                        :rules="lastnameRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="ชื่อเล่น *"
                        v-model="allChildren[i].nickname"
                        :loading="loading"
                        v-if="allChildren.length"
                        :rules="nicknameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="sexItems"
                        v-model="allChildren[i].sex"
                        :rules="sexRules"
                        label="เพศ *"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <member-date-picker
                        @saveDate="allChildren[i].birthday = $event"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-row class="mt-2">
        <v-col>
          <v-btn
            color="green"
            class="white--text"
            depressed
            block
            @click="checkTel"
            :loading="loading"
            :disabled="!valid"
            >ยืนยัน</v-btn
          >
          <result-add-button
            @closeDialog="resultDialog = $event"
            :successProps="success"
            :resultDialogProps="resultDialog"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Loading from "@/components/Loading.vue";
import AddressField from "@/components/AddressField.vue";
import memberDatePicker from "./member-date-picker.vue";
import ResultAddButton from "./result-add-button.vue";
import nationalityJson from "../../../.././../nationality.json";
import axios from "axios";

export default {
  components: { memberDatePicker, ResultAddButton, AddressField, Loading },
  data() {
    return {
      loading: false,
      valid: false,
      resultDialog: false,
      success: null,
      panels: [0],
      items: 1,
      sexItems: ["ชาย", "หญิง", "ไม่ระบุ"],
      member: {
        firstname: "",
        lastname: "",
        sex: "",
        birthday: "",
        tel: "",
        email: "",
        lineId: "",
        childrenQty: null,
        nationality: "",
        address: {
          subDistrict: "",
          district: "",
          province: "",
          postcode: "",
        },
        note: "",
      },
      allChildren: [],
      isUsed: null,
      notEmpytyRules: [(v) => !!v || "กรุณากรอกข้อมูล"],
      firstnameRules: [(v) => !!v || "กรุณากรอกชื่อ"],
      lastnameRules: [(v) => !!v || "กรุณากรอกนามสกุล"],
      nicknameRules: [(v) => !!v || "กรุณากรอกชื่อเล่น"],
      sexRules: [(v) => !!v || "กรุณากรอกช่องนี้"],
      telRules: [
        (v) => !!v || "กรุณากรอกหมายเลขโทรศัพท์",
        (v) => /[0]{1}[0-9]{9}/.test(v) || "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง",
      ],
      emailRules: [
        (v) => !!v || "กรุณากรอกอีเมล",
        (v) => /.+@.+[.]+./.test(v) || "กรุณากรอกอีเมลให้ถูกต้อง",
      ],
      childrenRules: [(v) => !!v || "กรุณากรอกจำนวนสมาชิก"],
      nationalityRules: [(v) => !!v || "กรุณากรอกสัญชาติ"],
    };
  },
  computed: {
    nationalityList() {
      const list = [];
      nationalityJson.map((item) => list.push(item.name));
      return list;
    },
  },
  methods: {
    openPanels() {
      this.allChildren = [];
      this.items = Number(this.member.childrenQty);
      this.panels = [...Array(this.items + 1).keys()].map((k, i) => {
        this.allChildren.push({
          firstname: "",
          lastname: "",
          nickname: "",
          birthday: "",
          sex: "",
        });
        return i;
      });
      this.allChildren.pop();
    },
    saveBirthdayMember(date) {
      this.member.birthday = date;
    },
    async createMember() {
      try {
        this.loading = true;
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URI}/member`,
          data: { member: this.member, children: this.allChildren },
        });

        this.success = true;
        this.alertSuccess("เพิ่มสมาชิกสำเร็จ");
        this.$router.push({ name: "Member-Management" });
      } catch (error) {
        this.success = false;
        this.alertError("พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    async checkTel() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URI}/member/check/tel`,
          data: { tel: this.member.tel },
        });
        if (data.isUsed) {
          this.isUsed = data.isUsed;
          this.member.tel = "";
          setTimeout(() => {
            this.isUsed = false;
          }, 5000);
        } else {
          this.createMember();
        }
        this.loading = false;
      } catch (error) {
        this.alertError("พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    onSelectAddress(address) {
      this.member.address.subDistrict = address?.subDistrict || "";
      this.member.address.district = address?.district || "";
      this.member.address.province = address?.province || "";
      this.member.address.postcode = address?.postcode || "";
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style></style>
