<template>
  <v-container class="mx-0 my-0 px-0 py-0">
    <v-form ref="form" lazy-validation>
      <!-- <div class="text-center mb-2">สาขาตรัง</div> -->
      <v-card class="px-3 py-5 ma-4" outlined>
        <v-row class="card-header">
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <h2>ผู้เข้าร่วม 1</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="member.firstname"
              :rules="firstnameRules"
              label="ชื่อ"
            />
            <v-text-field
              v-model="member.lastname"
              :rules="lastnameRules"
              label="นามสกุล"
            />
            <v-text-field
              v-model="member.nickname"
              :rules="nicknameRules"
              label="ชื่อเล่น"
            />
            <v-select
              v-model="member.ageGroup"
              :items="ageItems"
              item-text="text"
              item-value="value"
              :rules="ageGroupRules"
              label="ประเภท"
            />
            <v-select
              v-model="member.sex"
              :items="sexItems"
              :rules="sexRules"
              label="เพศ"
            />
            <DatePicker
              v-model="member.birthday"
              :rules="birthdayRules"
              label="วันเกิด"
            />
          </v-col>
        </v-row>
      </v-card>
      <div v-if="children.length === participants - 1">
        <v-card
          v-for="(item, index) in children"
          :key="index"
          class="px-3 py-5 ma-4"
          outlined
        >
          <v-row class="card-header">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <h2>ผู้เข้าร่วม {{ index + 2 }}</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="children[index].firstname"
                :rules="firstnameRules"
                label="ชื่อ"
              />
              <v-text-field
                v-model="children[index].lastname"
                :rules="lastnameRules"
                label="นามสกุล"
              />
              <v-text-field
                v-model="children[index].nickname"
                :rules="nicknameRules"
                label="ชื่อเล่น"
              />
              <v-select
                v-model="children[index].ageGroup"
                :items="ageItems"
                item-text="text"
                item-value="value"
                :rules="ageGroupRules"
                label="ประเภท"
              />
              <v-select
                v-model="children[index].sex"
                :items="sexItems"
                :rules="sexRules"
                label="เพศ"
              />
              <DatePicker
                v-model="children[index].birthday"
                :rules="birthdayRules"
                label="วันเกิด"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div v-if="participants - 1 > children.length">
        <v-card
          v-for="(item, index) in participants - 1"
          :key="index"
          class="px-3 py-5 ma-4"
          outlined
        >
          <v-row class="card-header">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <h2>ผู้เข้าร่วม {{ index + 2 }}</h2>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="checkbox[index]"
                :label="`สมาชิกเดิม`"
              ></v-checkbox>
              <v-autocomplete
                v-if="checkbox[index]"
                v-model="form[index]"
                return-object
                :items="children"
                item-text="firstname"
                label="ชื่อจริง"
              ></v-autocomplete>
              <v-text-field
                v-else
                v-model="form[index].firstname"
                :rules="firstnameRules"
                label="ชื่อ"
              />
              <v-text-field
                v-model="form[index].lastname"
                :rules="lastnameRules"
                label="นามสกุล"
              />
              <v-text-field
                v-model="form[index].nickname"
                :rules="nicknameRules"
                label="ชื่อเล่น"
              />
              <v-select
                v-model="form[index].ageGroup"
                :items="ageItems"
                item-text="text"
                item-value="value"
                :rules="ageGroupRules"
                label="ประเภท"
              />
              <v-select
                v-model="form[index].sex"
                :items="sexItems"
                :rules="sexRules"
                label="เพศ"
              />
              <DatePicker
                v-model="form[index].birthday"
                :rules="birthdayRules"
                label="วันเกิด"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-form>
    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          type="submit"
          block
          depressed
          color="success"
          :loading="loading"
          @click="submit()"
        >
          ยืนยัน
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "@/components/DatePicker.vue";

export default {
  components: {
    DatePicker,
  },
  props: {
    participants: {
      type: Number,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      checkbox: [],
      menu2: false,
      select: "",
      customerInfo: {},
      kidsinfo: [],
      lineUid: "",
      form: [],
      valid: false,
      activePicker: null,
      bridthdayDate: null,
      menu: false,
      firstnameRules: [(v) => !!v || "กรุณากรอกชื่อ"],
      lastnameRules: [(v) => !!v || "กรุณากรอกนามสกุล"],
      nicknameRules: [(v) => !!v || "กรุณากรอกชื่อเล่น"],
      sexRules: [(v) => !!v || "กรุณาเลือกข้อมูลเพศ"],
      birthdayRules: [(v) => !!v || "กรุณาเลือกวันเกิด"],
      ageGroupRules: [(v) => !!v || "กรุณาเลือกประเภท"],
      rules: {
        required: (v) => !!v || "กรุณากรอกช่องนี้",
        email: (v) => /.+@.+\..+/.test(v) || "กรุณากรอกอีเมล",
      },
      sexItems: ["ชาย", "หญิง", "ไม่ระบุ"],
      ageItems: [
        { text: "ผู้ใหญ่", value: "Adult" },
        { text: "เด็ก", value: "Young" },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.addform();
  },
  methods: {
    addform() {
      for (let i = 1; i < this.participants; i++) {
        this.form.push({
          firstname: "",
          lastname: "",
          nickname: "",
          birthday: "",
          ageGroup: "",
          sex: "",
        });
        this.checkbox.push(true);
      }
    },
    submit() {
      const valid = this.$refs.form.validate();
      const payload = [this.member];

      if (this.children.length === this.participants - 1) {
        payload.push(...this.children);
      }
      if (this.participants - 1 > this.children.length) {
        payload.push(...this.form);
      }

      if (valid) {
        this.$emit("input", payload);
      }
      // let payload
      // payload = [this.member]
      // if (this.form.length >= 1) payload = [this.member, ...this.form]
      // if (valid) {
      //   this.$emit('input', payload)
      // }
    },
  },
};
</script>
