<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          class="ma-2 font-weight-bold"
          v-bind="attrs"
          v-on="on"
          color="primary"
          text-color="white"
          @click="$emit('input')"
        >
          แจ้งเตือนการเปลี่ยนรอบ
          <v-avatar v-if="count > 0" right class="red darken-1">
            {{ count }}
          </v-avatar>
        </v-chip>
      </template>
      <v-card elevation="8">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>เปลี่ยนรอบการจอง</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-4 mt-10">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            :footer-props="footer"
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
            class="elevation-4"
          >
            <template v-slot:item.orderId="{ item }">
              <span class="d-flex">
                <v-icon v-if="!item.read" color="red">mdi-alert-circle</v-icon>
                {{ item.orderId }}
              </span>
            </template>
            <template v-slot:item.branch="{ item }">
              {{ item.branch | formatBranch }}
            </template>

            <template v-slot:item.oldDate="{ item }">
              <span class="font-weight-bold red--text">{{
                formatDateThai(item.oldDate)
              }}</span>
            </template>
            <template v-slot:item.oldTime="{ item }">
              <span class="font-weight-bold red--text">{{ item.oldTime }}</span>
            </template>
            <template v-slot:item.newDate="{ item }">
              <span class="font-weight-bold green--text">{{
                formatDateThai(item.newDate)
              }}</span>
            </template>
            <template v-slot:item.newTime="{ item }">
              <span class="font-weight-bold green--text">{{
                item.newTime
              }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ formatDateThai(item.createdAt) }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.type | bookingType }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                :to="
                  item.type !== 'group'
                    ? '/order/detail/' + item.orderId
                    : '/order/group/' + item.orderId
                "
                color="primary"
                >รายการจอง</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    schedule: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
    items() {
      const arr = [];
      if (this.schedule.length > 0) {
        for (let i = 0; i < this.schedule.length; i++) {
          arr.push({
            orderId: this.schedule[i].oldOrder[0].orderId,
            branch: this.schedule[i].oldOrder[0].branch,
            type: this.schedule[i].oldOrder[0].type,
            oldDate: this.schedule[i].oldOrder[0].schedule.date,
            newDate: this.schedule[i].newOrder[0].schedule.date,
            oldTime: `${this.schedule[i].oldOrder[0].schedule.startTime} - ${this.schedule[i].oldOrder[0].schedule.endTime}`,
            newTime: `${this.schedule[i].newOrder[0].schedule.startTime} - ${this.schedule[i].newOrder[0].schedule.endTime}`,
            createdAt: this.schedule[i].createdAt,
            read: this.schedule[i].read,
          });
        }
      }
      return arr;
    },
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      headers: [
        {
          text: "หมายเลขการจอง",
          value: "orderId",
          align: "center",
          width: "7rem",
        },
        { text: "วันที่ทำรายการ", value: "createdAt" },
        { text: "สาขา", value: "branch" },
        { text: "ประเภทการจอง", value: "type", align: "center" },
        { text: "วันที่จอง (รอบเก่า)", value: "oldDate" },
        { text: "รอบเวลา (รอบเก่า)", value: "oldTime" },
        { text: "วันที่จอง (รอบใหม่)", value: "newDate" },
        { text: "รอบเวลา (รอบใหม่)", value: "newTime" },
        { text: "", value: "action" },
      ],
    };
  },
};
</script>
