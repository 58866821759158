<template>
  <v-expansion-panel class="mt-0">
    <v-expansion-panel-header color="#8bcef5" class="py-5">
      <h3 class="white--text">
        <v-icon dark left>mdi-account</v-icon> ผู้เข้าร่วมคนที่ {{ index }}
        <span
          :class="
            atk && atk.atkStatus === 'sended'
              ? 'green--text dark'
              : 'red--text dark'
          "
          >({{
            atk && atk.atkStatus === "sended"
              ? "ส่งผลตรวจ ATK แล้ว"
              : "ยังไม่ส่งผลตรวจ ATK"
          }})</span
        >
      </h3>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <div class="text-right mt-4">
                <v-btn
                  v-if="!isEdit"
                  class="text-right font-weight-bold"
                  color="#369CE0"
                  dark
                  @click="onChangeEdit()"
                  ><v-icon left> mdi-account-edit </v-icon>แก้ไขข้อมูล</v-btn
                >
                <v-btn
                  v-else
                  class="text-right font-weight-bold"
                  color="orange"
                  dark
                  @click="onColoseEdit()"
                  ><v-icon left> mdi-backup-restore</v-icon>ยกเลิก</v-btn
                >
                <!-- <v-btn
                  @click="onDelete()"
                  class="text-right font-weight-bold"
                  color="red"
                  dark
                  ><v-icon left>mdi-account-remove</v-icon>ลบ</v-btn
                > -->
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ชื่อ *"
                v-model="participant.firstname"
                :loading="loading"
                :rules="[notEmpytyRules]"
                required
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="นามสกุล *"
                :rules="[notEmpytyRules]"
                v-model="participant.lastname"
                :loading="loading"
                required
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ชื่อเล่น"
                v-model="participant.nickname"
                :loading="loading"
                required
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="participant.sex"
                :items="sexItems"
                :rules="[notEmpytyRules]"
                item-value="value"
                item-text="text"
                label="เพศ *"
                required
                :disabled="!isEdit"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDate(participant.birthday)"
                    label="วันเกิด"
                    readonly
                    :rules="[notEmpytyRules]"
                    disabled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="th"
                  v-model="participant.birthday"
                  @input="datePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="อายุ"
                disabled
                :value="participant.birthday | formatAge"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="participant.note"
                label="หมายเหตุ"
                :disabled="!isEdit"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isEdit">
            <v-col cols="12">
              <div class="text-right">
                <v-btn
                  dark
                  color="green"
                  @click.prevent="onUpdate()"
                  class="font-weight-bold"
                >
                  <v-icon left>mdi-account-check</v-icon>บันทึก</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-row v-if="atk && atk.atkUrl">
            <v-col cols="12">
              <div class="">
                <div class="font-weight-bold green--text mb-2">ผลตรวจ ATK</div>
                <viewer>
                  <img
                    :src="atk.atkUrl"
                    alt="ผลตรวจ ATK"
                    style="width: 150px; hegiht: 150"
                    class="pointer"
                  />
                </viewer>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    participant: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [Number, String],
      default: 1,
    },
    atk: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    notEmpytyRules() {
      return (v) => !!v || "กรุณากรอกข้อมูล";
    },
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
  },
  mounted() {
    if (this.participant) {
      this.participantVitural = { ...this.participant };
      this.form = this.participant;
      this.form.birthday = dayjs(this.participant.birthday).format(
        "YYYY-MM-DD"
      );
    }
  },
  data() {
    return {
      loading: false,
      isEdit: false,
      participantVitural: {},
      datePicker: "",
      form: {},
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD MMMM BBBB");
    },
    onUpdate() {
      this.$emit("input", this.participant);
      this.isEdit = !this.isEdit;
    },
    onDelete() {
      this.$emit("delete", this.index - 1);
    },
    onChangeEdit() {
      this.isEdit = !this.isEdit;
    },
    onColoseEdit() {
      this.participant = { ...this.participantVitural };
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style>
.images {
  cursor: pointer;
  max-height: 2rem;
  max-width: 1.5rem;
}
.pointer {
  cursor: pointer;
}
</style>
