<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-form
            @submit.prevent="submit()"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-toolbar color="#3CB2FF" dark flat>
              <v-toolbar-title>เข้าสู่ระบบ</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="username"
                label="ชื่อผู้ใช้งาน"
                name="username"
                type="text"
                :rules="usernameRules"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                id="password"
                label="รหัสผ่าน"
                :rules="passwordRules"
                :error-messages="error"
                name="password"
                type="password"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="#3CB2FF"
                class="white--text"
                depressed
                type="submit"
              >
                เข้าสู่ระบบ
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import jwt from "jsonwebtoken";
export default {
  data() {
    return {
      username: "",
      password: "",
      valid: false,
      usernameRules: [(v) => !!v || "กรุณากรอกชื่อผู้ใช้งาน"],
      passwordRules: [(v) => !!v || "กรุณากรอกรหัสผ่าน"],
      error: "",
    };
  },
  mounted() {
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    const token = vuex?.User?.info?.token;
    jwt.verify(token, "jx0hYKKvO0ajJm9WWfpQFFvy2MQo1BNrOwOfES7xc", (err) => {
      if (!err) {
        this.$router.push("/member");
      }
    });
  },
  methods: {
    ...mapActions({
      setInfo: "User/setInfo",
    }),
    async submit() {
      const vaild = this.$refs.form.validate();
      if (vaild) {
        try {
          const { data } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URI}/auth/login`,
            data: {
              username: this.username,
              password: this.password,
            },
          });
          this.$toast.success("เข้าสู่ระบบสำเร็จ", {
            position: "top-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          this.setInfo(data.data);
          this.$router.go(0);
        } catch (error) {
          this.error = "username หรือ password ผิด";
        }
      }
    },
  },
};
</script>
