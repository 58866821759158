<template>
  <v-col cols="12" class="px-0 py-0">
    <Loading v-if="loading" />
    <v-expansion-panel v-for="(item, i) in allChildren" :key="i" class="mt-0">
      <v-expansion-panel-header color="#8bcef5" class="py-5">
        <h3 class="white--text">
          <v-icon dark left>mdi-account</v-icon> สมาชิกคนที่ {{ i + 1 }}
        </h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <div class="text-right mt-4">
                  <v-btn
                    class="text-right font-weight-bold mr-4"
                    color="#369CE0"
                    dark
                    v-if="!item.isEdit"
                    @click="onChangeEdit(i)"
                    ><v-icon left> mdi-account-edit </v-icon>แก้ไขข้อมูล</v-btn
                  >
                  <v-btn
                    v-else
                    class="text-right font-weight-bold mr-4"
                    color="orange"
                    dark
                    @click="onCloseEdit(i)"
                    ><v-icon left> mdi-backup-restore</v-icon>ยกเลิก</v-btn
                  >
                  <v-btn
                    @click="onDelete(item.childrenId)"
                    class="text-right font-weight-bold"
                    color="red"
                    dark
                    ><v-icon left>mdi-account-remove</v-icon>ลบ</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="ชื่อ *"
                  v-model="allChildren[i].firstname"
                  :loading="loading"
                  :rules="[notEmpytyRules]"
                  v-if="allChildren.length"
                  required
                  :disabled="!item.isEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="นามสกุล *"
                  :rules="[notEmpytyRules]"
                  v-model="allChildren[i].lastname"
                  :loading="loading"
                  v-if="allChildren.length"
                  required
                  :disabled="!item.isEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="ชื่อเล่น"
                  v-model="allChildren[i].nickname"
                  :loading="loading"
                  v-if="allChildren.length"
                  required
                  :disabled="!item.isEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="allChildren[i].sex"
                  :items="sexItems"
                  :rules="[notEmpytyRules]"
                  item-value="value"
                  item-text="text"
                  label="เพศ *"
                  required
                  :disabled="!item.isEdit"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="datePicker[i]"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(allChildren[i].birthday)"
                      label="วันเกิด"
                      readonly
                      :rules="[notEmpytyRules]"
                      :disabled="!item.isEdit"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="th"
                    v-model="allChildren[i].birthday"
                    @input="datePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="อายุ"
                  disabled
                  :value="allChildren[i].birthday | formatAge"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="allChildren[i].note"
                  label="หมายเหตุ"
                  :disabled="!item.isEdit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="item.isEdit">
              <v-col cols="12">
                <div class="text-right">
                  <v-btn
                    dark
                    color="green"
                    @click.prevent="onUpdate(i)"
                    class="font-weight-bold"
                  >
                    <v-icon left>mdi-account-check</v-icon>บันทึก</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-col>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      datePicker: false,
      date: "",
      isEdit: [],
      items: [],
      allChildren: [],
      allChildrenVirtual: [],
    };
  },
  computed: {
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
    notEmpytyRules() {
      return (v) => !!v || "กรุณากรอกข้อมูล";
    },
  },
  mounted() {
    this.getChildren();
  },
  methods: {
    async getChildren() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/children/${this.$route.params.memberId}/member`,
        });
        const arr = [];
        data.map((kid) => {
          arr.push({
            childrenId: kid.childrenId,
            firstname: kid.firstname,
            lastname: kid.lastname,
            nickname: kid.nickname,
            birthday: dayjs(kid.birthday).format("YYYY-MM-DD"),
            sex: kid.sex,
            note: kid.note,
            isEdit: false,
          });
        });
        this.allChildren = arr;
        this.items = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onUpdate(index) {
      const validate = this.$refs.form[index].validate();
      if (validate) {
        this.$emit("input", this.allChildren[index]);
        this.allChildren[index].isEdit = !this.allChildren[index].isEdit;
        setTimeout(async () => {
          await this.getChildren();
        }, 1000);
      }
    },
    onDelete(id) {
      this.$emit("delete", id);
    },
    onChangeEdit(index) {
      this.allChildren[index].isEdit = !this.allChildren[index].isEdit;
    },
    onCloseEdit(index) {
      const arr = this.items.map((kid) => {
        return {
          childrenId: kid.childrenId,
          firstname: kid.firstname,
          lastname: kid.lastname,
          nickname: kid.nickname,
          birthday: dayjs(kid.birthday).format("YYYY-MM-DD"),
          sex: kid.sex,
          note: kid.note,
        };
      });
      this.allChildren[index].childrenId = arr[index].childrenId;
      this.allChildren[index].firstname = arr[index].firstname;
      this.allChildren[index].lastname = arr[index].lastname;
      this.allChildren[index].nickname = arr[index].nickname;
      this.allChildren[index].birthday = arr[index].birthday;
      this.allChildren[index].sex = arr[index].sex;
      this.allChildren[index].note = arr[index].note;
      this.allChildren[index].isEdit = !this.allChildren[index].isEdit;
    },
    formatDate(date) {
      return dayjs(date).format("DD MMMM BBBB");
    },
  },
};
</script>

<style scoped>
::v-deep div.v-text-field__slot > input[disabled] {
  color: rgba(0, 0, 0, 0.7);
}
</style>
