var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2 font-weight-bold",attrs:{"color":"primary","text-color":"white"},on:{"click":function($event){return _vm.$emit('input')}}},'v-chip',attrs,false),on),[_vm._v(" แจ้งเตือนการเปลี่ยนรอบ "),(_vm.count > 0)?_c('v-avatar',{staticClass:"red darken-1",attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.count)+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"elevation":"8"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("เปลี่ยนรอบการจอง")]),_c('v-spacer')],1),_c('div',{staticClass:"px-4 mt-10"},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"footer-props":_vm.footer,"no-data-text":"ไม่พบข้อมูล","no-results-text":"ไม่พบข้อมูล"},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex"},[(!item.read)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle")]):_vm._e(),_vm._v(" "+_vm._s(item.orderId)+" ")],1)]}},{key:"item.branch",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatBranch")(item.branch))+" ")]}},{key:"item.oldDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v(_vm._s(_vm.formatDateThai(item.oldDate)))])]}},{key:"item.oldTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold red--text"},[_vm._v(_vm._s(item.oldTime))])]}},{key:"item.newDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold green--text"},[_vm._v(_vm._s(_vm.formatDateThai(item.newDate)))])]}},{key:"item.newTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold green--text"},[_vm._v(_vm._s(item.newTime))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateThai(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bookingType")(item.type))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"to":item.type !== 'group'
                  ? '/order/detail/' + item.orderId
                  : '/order/group/' + item.orderId,"color":"primary"}},[_vm._v("รายการจอง")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }