import Vue from "vue";
import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import customParseFormat from "dayjs/plugin/customParseFormat";

import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";

dayjs.extend(buddhistEra);

// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(customParseFormat);
Vue.prototype.$dayjs = dayjs;

Vue.filter("formatMiniDate", (val) =>
  dayjs(val).locale("th").format("DD/MM/BBBB")
);

Vue.filter("formatDate", (val) =>
  dayjs(val).locale("th").format("DD MMMM BBBB HH:mm:ss")
);

Vue.filter("formatDateTime", (val) =>
  dayjs(val).locale("th").format("DD/MM/BBBB HH:mm")
);

Vue.filter("formatDateDefault", (val) =>
  dayjs(val).locale("th").format("DD/MM/BBBB ")
);

Vue.filter("formatAge", (val) => dayjs(new Date()).diff(dayjs(val), "year"));
