<template>
  <v-card v-bind="attrs" v-on="{ ...$listeners }" dark>
    <ConfirmDialog
      message="ต้องการดำเนินการต่อหรือไม่ ?"
      ref="confirmDialogue"
    />
    <div class="d-flex flex-no-wrap justify-space-between">
      <slot>
        <div>
          <v-card-title class="text-h4" v-text="title"></v-card-title>
          <v-card-subtitle v-text="description"></v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2">
            <div>สาขา: {{ branch | formatBranch }}</div>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2">
            <div>วันที่ซื้อ: {{ formatDateTimeThai(updatedAt) }}</div>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2">
            <v-menu
              v-if="isEdit"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="expiredFormat"
                  label="วันหมดอายุ"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="nowDate"
                locale="th"
                v-model="form.expired"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <div v-else>วันหมดอายุ: {{ formatDateTimeThai(expired) }}</div>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2">
            <v-text-field
              v-if="isEdit"
              label="คงเหลือ"
              v-model="form.items"
            ></v-text-field>
            <div v-else>คงเหลือ: {{ items }}</div>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 mb-2">
            <div>
              สถานะ:<v-chip
                small
                class="ma-2 font-weight-bold"
                :color="isExpired ? 'red' : 'green'"
                label
                text-color="white"
              >
                <v-icon left> mdi-timer-remove </v-icon>
                {{ isExpired ? "หมดอายุ" : "ใช้งานได้" }}
              </v-chip>
            </div>
          </v-card-subtitle>
          <v-card-actions>
            <div v-if="isEdit">
              <v-btn class="ml-2 mt-5" outlined rounded @click="onEdit()">
                <v-icon>mdi-pencil</v-icon> บันทึก
              </v-btn>
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                @click="isEdit = !isEdit"
              >
                <v-icon>mdi-delete</v-icon> ยกเลิก
              </v-btn>
            </div>
            <div v-else :class="$vuetify.breakpoint.width <= 460 && 'd-flex'">
              <v-btn
                class="ml-2 mt-5"
                outlined
                rounded
                @click="isEdit = !isEdit"
              >
                <v-icon>mdi-pencil</v-icon> แก้ไข
              </v-btn>
              <v-btn class="ml-2 mt-5" outlined rounded @click="onDelete()">
                <v-icon>mdi-delete</v-icon> ลบ
              </v-btn>
            </div>
          </v-card-actions>
        </div>
      </slot>

      <v-avatar
        class="ma-3"
        v-if="$vuetify.breakpoint.width >= 410"
        size="125"
        tile
      >
        <v-img src="/svg/PlanToys_Logo-01.svg"></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>
<script>
import dayjs from "dayjs";
import ConfirmDialog from "../Confirm.vue";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";

dayjs.extend(buddhistEra);
export default {
  components: {
    ConfirmDialog,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    branch: {
      type: String,
      default: "",
    },
    updatedAt: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    items: {
      type: Number,
      default: 0,
    },
    packageId: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    expired: {
      type: String,
      default: "",
    },
    minPrice: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
    nowDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    isExpired() {
      return dayjs(this.expired).isBefore(new Date());
    },
    expiredFormat() {
      if (dayjs(this.form.expired).isValid()) {
        return dayjs(this.form.expired).format("DD MMMM BBBB");
      }
      return dayjs(this.expired).format("DD MMMM BBBB");
    },
  },
  data: () => ({
    isEdit: false,
    menu2: false,
    form: {
      expired: "",
      items: 0,
    },
    items: [
      {
        color: "#1F7087",
        src: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
        title: "Supermodel",
        artist: "Foster the People",
      },
      {
        color: "#952175",
        src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
        title: "Halcyon Days",
        artist: "Ellie Goulding",
      },
    ],
  }),
  mounted() {
    setTimeout(() => {
      this.form.expired = dayjs(this.expired).format("YYYY-MM-DD");
      this.form.items = this.items;
    }, 1500);
  },
  methods: {
    async onEdit() {
      const confirm = await this.$refs.confirmDialogue.show();
      if (confirm) {
        const hourMinute = dayjs(this.expired).format("HH:mm");
        const expired = dayjs(this.form.expired).format(
          `YYYY-MM-DD ${hourMinute}`
        );
        const payload = {
          packageId: this.packageId,
          expired: expired,
          items: this.form.items,
        };
        this.$emit("edit", payload);
        this.isEdit = false;
      }
    },
    async onDelete() {
      const confirm = await this.$refs.confirmDialogue.show();
      if (confirm) {
        this.$emit("delete", this.packageId);
      }
    },
  },
};
</script>
