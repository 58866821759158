var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[(_vm.loading)?_c('Loading'):_vm._e(),_c('v-expansion-panel-header',{staticClass:"py-5",attrs:{"color":"#8bcef5"}},[_c('h3',{staticClass:"white--text"},[_vm._v("รายการจอง")])]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.orders,"loading":_vm.loading},on:{"click:row":function($event){$event.type === 'group'
            ? _vm.$router.push(("/order/group/" + ($event.orderId)))
            : _vm.$router.push(("/order/detail/" + ($event.orderId)))}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" ไม่พบข้อมูล ")]},proxy:true},{key:"item.orderId",fn:function(ref){
            var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(item.orderId))])]}},{key:"item.schedule.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMiniDate")(item.schedule.date))+" ")]}},{key:"item.time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSchedule(item.schedule.startTime, item.schedule.endTime))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }