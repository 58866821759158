<template>
  <v-container :class="classObject">
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">เพิ่มสมาชิก</h2>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <member-add-expansion />
  </v-container>
</template>

<script>
import memberAddExpansion from "../components/member-add/member-add-expansion.vue";
export default {
  components: { memberAddExpansion },
  data() {
    return {
      breadItem: [
        {
          text: "รายชื่อสมาชิก",
          exact: true,
          disabled: false,
          link: true,
          to: { path: "/member" },
        },
        {
          text: "เพิ่มสมาชิก",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
  },
};
</script>

<style></style>
