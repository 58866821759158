<template>
  <v-menu
    ref="menu"
    v-model="menu1"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
        :value="formatDate(date)"
        :dense="dense"
        :placeholder="placeholder"
        :label="label"
        :rules="rules"
      />
    </template>
    <v-date-picker
      v-model="date"
      color="primary"
      locale="th-TH"
      :value="value"
      :range="range"
      :multiple="multiple"
      @input="$emit('input', $event)"
      @change="save($event)"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      menu1: false,
      date: "",
    };
  },
  props: {
    default: {
      type: String,
      default: dayjs().format("YYYY-MM-DD"),
    },
    value: {
      type: [String, Array],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    range: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    display() {
      const { toLocaleDateString, value } = this;

      if (!value) return "";

      if (Array.isArray(value)) {
        return this.sortDate(value)
          .map((date) => toLocaleDateString(date))
          .filter((date) => date)
          .join(" - ");
      }
      return toLocaleDateString(value);
    },
  },
  mounted() {
    this.date = this.default;
  },
  methods: {
    toLocaleDateString(date) {
      if (!date) return "";
      return new Date(date).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    sortDate(dates) {
      return [...dates].sort((start, end) => new Date(start) - new Date(end));
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (date) {
        return dayjs(date).format("D MMMM BBBB");
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
