<template>
  <v-card elevation="0" outlined class="pt-3 pb-5">
    <v-card-subtitle class="py-0"> เวลา </v-card-subtitle>
    <v-card-title class="justify-center">
      <h3 class="text-center text-secondary">{{ status }}</h3>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.card-text {
  font-weight: 600;
}
</style>
