<template>
  <v-app>
    <Navbar :isMobile="isMobile" v-if="navBarEnable" />
    <v-main :class="mainClassObject">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  components: { Navbar },
  name: "App",

  data: () => ({
    forbidRoutes: [
      "LIFF-Login",
      "Register",
      "Home",
      "NotFound",
      // "Create-Order",
    ],
  }),
  computed: {
    isMobile() {
      if (this.$vuetify.breakpoint.width > 960) {
        return false;
      } else {
        return true;
      }
    },
    navBarEnable() {
      return !this.forbidRoutes.includes(this.$route.name);
    },
    mainClassObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mx-8";
      } else {
        return "mx-1";
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Prompt", sans-serif;
}
.text-primary {
  color: #fdfdfd;
}
.text-secondary {
  color: #305279;
}
.breadcrumbs {
  padding: 0 !important;
}
.header {
  font-weight: 600;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
