<template>
  <v-card elevation="0" outlined class="pt-3 pb-5">
    <v-card-subtitle class="py-0"> เอกสาร </v-card-subtitle>
    <v-card-title class="justify-center">
      <h3
        class="text-center"
        :class="this.status ? 'green--text' : 'red--text'"
      >
        {{ statusText }}
      </h3>
      <v-btn
        v-if="this.status"
        :href="this.status"
        target="_blank"
        color="green"
        class="mt-5 font-weight-bold"
        dark
        block
        >DOWNLOAD PDF (Click)</v-btn
      >
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {
    statusText() {
      return this.status ? "ส่งแล้ว" : "ยังไม่ส่ง";
    },
  },
};
</script>
<style scoped>
.card-text {
  font-weight: 600;
}
</style>
