<template>
  <v-card outlined elevation="0">
    <!-- <v-row class="mt-1">
      <v-col class="d-flex justify-end pb-0 pt-2 pr-7">
        <v-icon color="grey darken-3" size="1.3rem">mdi-pencil-outline</v-icon>
      </v-col>
    </v-row> -->
    <v-card-title>รายละเอียดผู้จอง</v-card-title>
    <v-col cols="12" md="10" class="px-0 py-0">
      <v-container class="px-10">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.firstname"
              disabled
              label="ชื่อ"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.lastname"
              disabled
              label="นามสกุล"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="member.sex"
              :items="sexItems"
              item-text="text"
              item-value="value"
              label="เพศ"
              disabled
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :value="birthday"
              disabled
              label="วันเกิด"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="อายุ"
              disabled
              :value="member.birthday | formatAge"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.tel"
              disabled
              label="หมายเลขโทรศัพท์"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.email"
              disabled
              label="อีเมล"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.lineId"
              disabled
              label="LINE ID"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.nationality"
              disabled
              label="สัญชาติ"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="member.note"
              disabled
              label="หมายเหตุ"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-card>
</template>
<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    birthday() {
      return this.formatDate(this.member.birthday);
    },
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
  },
};
</script>
