<template>
  <v-col cols="12" class="px-2">
    <v-card v-if="!isEdit" outlined elevation="0">
      <v-row class="mt-1">
        <v-col class="d-flex justify-end pb-0 pt-2 pr-7">
          <v-btn
            v-if="!isEdit"
            class="text-right font-weight-bold"
            color="#369CE0"
            dark
            @click="isEdit = !isEdit"
            ><v-icon left> mdi-account-edit </v-icon>แก้ไขข้อมูล</v-btn
          >
        </v-col>
      </v-row>
      <v-col cols="12" md="10" class="px-0 py-0">
        <v-container class="px-10">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="ชื่อ"
                v-model="memberProps.firstname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="นามสกุล"
                v-model="memberProps.lastname"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :items="sexItems"
                item-text="text"
                item-value="value"
                label="เพศ"
                disabled
                v-model="memberProps.sex"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="วันเกิด"
                v-model="formatBirthDay"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="อายุ"
                disabled
                :value="memberProps.birthday | formatAge"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="หมายเลขโทรศัพท์"
                v-model="memberProps.tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="อีเมล"
                v-model="memberProps.email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                disabled
                label="สัญชาติ"
                v-model="memberProps.nationality"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>

    <v-card v-if="isEdit && form" outlined elevation="0">
      <v-row class="mt-1">
        <v-col class="d-flex justify-end pb-0 pt-2 pr-7">
          <v-btn
            class="text-right font-weight-bold"
            color="orange"
            dark
            @click="onClose()"
            ><v-icon left> mdi-backup-restore</v-icon>ยกเลิก</v-btn
          >
        </v-col>
      </v-row>
      <v-col cols="12" md="10" class="px-0 py-0">
        <v-container class="px-10">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="ชื่อ"
                v-model="memberProps.firstname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="นามสกุล"
                v-model="memberProps.lastname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                :items="sexItems"
                item-text="text"
                item-value="value"
                label="เพศ"
                v-model="memberProps.sex"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="memberProps.birthday"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatBirthDay"
                    label="วันเดือนปีเกิด"
                    append-icon="mdi-calendar"
                    readonly
                    disabled
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="memberProps.birthday"
                  locale="th"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    ยกเลิก
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(memberProps.birthday)"
                  >
                    ตกลง
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="หมายเลขโทรศัพท์"
                v-model="memberProps.tel"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="อีเมล"
                v-model="memberProps.email"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="memberProps.nationality"
                :items="nationalityList"
                placeholder="สัญชาติ"
                auto-select-first
              >
                <template #no-data>
                  <span>ไม่พบข้อมูล</span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-row class="mr-2 my-4" v-if="isEdit">
        <v-col cols="12">
          <div class="text-right">
            <v-btn
              dark
              color="green"
              @click.prevent="onSubmit($event)"
              class="font-weight-bold"
            >
              <v-icon left>mdi-account-check</v-icon>บันทึก</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import dayjs from "dayjs";
import nationalityJson from "../../../../../nationality.json";
export default {
  props: {
    memberProps: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.memberVirtual = { ...this.memberProps };
  },
  data() {
    return {
      memberVirtual: null,
      menu: false,
      isEdit: false,
      form: { ...this.memberProps },
    };
  },
  computed: {
    formatBirthDay() {
      return dayjs(this.memberProps.birthday).format("DD MMMM BBBB");
    },
    nationalityList() {
      const list = [];
      nationalityJson.map((item) => list.push(item.name));
      return list;
    },
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
  },
  methods: {
    onSubmit() {
      this.$emit("input", this.memberProps);
      this.isEdit = !this.isEdit;
    },
    onClose() {
      this.memberProps = { ...this.memberVirtual };
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style scoped>
::v-deep div.v-text-field__slot > input[disabled] {
  color: rgba(0, 0, 0, 0.7);
}
::v-deep div.v-text-field__slot > label {
  color: #305279;
}
</style>
