<template>
  <v-container :class="classObject">
    <Loading v-if="loading" />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">รายการจอง</h2>
      </v-col>
    </v-row>
    <!-- <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row> -->

    <v-row class="mt-10">
      <v-col cols="12">
        <v-card color="px-6 py-6">
          <v-row>
            <v-col cols="12" sm="5"
              ><h3 class="text-secondary">
                หมายเลขการจอง : {{ order.orderId }}
              </h3></v-col
            >
            <v-col cols="12" sm="3"
              ><h3 class="text-secondary">
                สาขา: {{ order.branch === "trang" ? "ตรัง" : "สาทร" }}
              </h3>
            </v-col>
            <v-col cols="12" sm="4"
              ><h3 class="text-secondary">
                ประเภทการจอง: {{ order.isGroup ? "กลุ่ม" : "ทั่วไป" }}
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="3" class="px-2">
        <StatusCard :status="orderStatus" @input="saveStatus($event)" />
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <DateCard :status="scheduleDate" />
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <TimeCard :status="scheduleStartTime" />
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <PaticipantCard :status="order.joinQty" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="px-2">
        <FileStatusCard :status="order.verifyNote" />
      </v-col>
      <v-col cols="6" class="px-2">
        <PriceCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="px-2">
        <MemberForm :member="member" />
      </v-col>
      <v-col cols="12" class="px-2">
        <DetailOrderForm :order="order" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "../../../../utils/axios";
import StatusCard from "@/components/Order/Group/Card/StatusCard.vue";
import DateCard from "@/components/Order/Group/Card/DateCard.vue";
import TimeCard from "@/components/Order/Group/Card/TimeCard.vue";
import PaticipantCard from "@/components/Order/Group/Card/PaticipantCard.vue";
import FileStatusCard from "@/components/Order/Group/Card/FileStatusCard.vue";
import PriceCard from "@/components/Order/Group/Card/PriceCard.vue";
import MemberForm from "@/components/Order/Group/MemberForm.vue";
import DetailOrderForm from "@/components/Order/Group/DetailOrderForm.vue";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
    StatusCard,
    DateCard,
    TimeCard,
    PaticipantCard,
    FileStatusCard,
    PriceCard,
    MemberForm,
    DetailOrderForm,
  },
  async mounted() {
    await this.getOrder();
    await this.getMember();
  },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    orderStatus() {
      return this.order.orderStatus;
    },
    scheduleDate() {
      if (this.order.schedule) return this.order.schedule.date;
      return "";
    },
    scheduleStartTime() {
      if (this.order.schedule) {
        if (
          this.order.schedule.startTime === "00:00" &&
          this.order.schedule.endTime === "23:59"
        ) {
          return "Allday";
        }
        return `${this.order.schedule.startTime} - ${this.order.schedule.endTime} น.`;
      }
      return "";
    },
    scheduleEndTime() {
      if (this.order.schedule) return this.order.schedule.endTime;
      return "";
    },
    startTimeAndEndTime() {
      if (this.order.schedule) {
        if (
          this.order.schedule.startTime === "00:00" &&
          this.order.schedule.endTime === "23:59"
        ) {
          return "Allday";
        }
        return `${this.order.schedule.startTime} - ${this.order.schedule.endTime} น.`;
      }
      return "";
    },
  },
  data() {
    return {
      loading: false,
      branch: "",
      isConfirmActionDialog: true,
      // breadItem: [
      //   {
      //     text: "รายการจอง",
      //     exact: true,
      //     disabled: false,
      //     link: true,
      //     to: { path: "/order" },
      //   },
      //   {
      //     text: "รายละเอียดการจอง",
      //     disabled: true,
      //   },
      // ],
      order: {},
      member: {},
    };
  },
  methods: {
    async getOrder() {
      try {
        this.loading = true;
        const { data } = await axios({
          method: "get",
          url: `/order-group/${this.$route.params.orderId}`,
        });

        this.order = data.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getMember() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await axios({
          method: "get",
          url: `/member/${this.order.member.memberId}`,
        });

        this.member = data.member;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async saveStatus(status) {
      this.loading = true;
      try {
        if (status === "confirmed") {
          await axios.patch(
            `/order-group/status/${this.$route.params.orderId}`,
            { orderStatus: status }
          );
        }
        if (status === "rejected") {
          await axios.post(
            `/order-group/${this.$route.params.orderId}/rejected`
          );
        }
        if (status === "canceled") {
          await axios.post(
            `/order-group/${this.$route.params.orderId}/canceled`
          );
        }

        await this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    // async saveDate() {
    //   this.loading = true;
    //   try {
    //     await axios({
    //       method: "patch",
    //       url: `${process.env.VUE_APP_API_URI}/order/${this.$route.params.orderId}`,
    //       data: { "schedule.date": this.order.schedule.date },
    //     });

    //     this.getOrder();
    //     this.alertSuccess("ดำเนินการสำเร็จ");
    //   } catch (error) {
    //     this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    async saveMemberEdit(event) {
      try {
        this.loading = true;
        await axios.patch(
          `${process.env.VUE_APP_API_URI}/order/${this.$route.params.orderId}`,
          {
            member: event,
          }
        );
        this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style></style>
