import Vue from "vue";
import VueViewer from "v-viewer";
import Toast from "vue-toastification";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Print from "vue-print-nb";

import "./plugins/filter";
import "./plugins/day";
import "./plugins/high-chart";
import "./plugins/format";
import "./plugins/notification";
import "@/plugins/Prototype";
import "vue-toastification/dist/index.css";
import "viewerjs/dist/viewer.css";

Vue.use(Print);
Vue.config.productionTip = false;

const options = {};

Vue.use(Toast, options);
Vue.use(VueViewer);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
