<template>
  <v-container :class="classObject">
    <ConfirmDialog
      message="ต้องการดำเนินการต่อหรือไม่ ?"
      ref="confirmDialogue"
    />
    <Loading v-if="loading" />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">รายละเอียดสมาชิก</h2>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end py-0" cols="12" sm="6" lg="5">
        <v-btn
          color="#369CE0"
          width="13rem"
          dark
          :disabled="editable"
          @click="onEditProfile()"
        >
          <v-icon> mdi-account-edit </v-icon>
          แก้ไขข้อมูล
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end py-0" cols="12" sm="6" lg="5">
        <PackageDialog
          :packages="packages"
          :member-package="memberPackage"
          @choose-package="onChoosePackage($event)"
          @pagination-page="paginationPage($event)"
          @pagination-page-member-package="paginationPageMemberPackage($event)"
          @edit="onUpdateMemberPackage($event)"
          @delete="onDeleteMemberPackage($event)"
          @search="onSearchPackage($event)"
          @search-member-package="onSearchMemberPackage($event)"
          @change-branch="onChangeBranch($event)"
          @change-branch2="onChangeBranch2($event)"
        />
      </v-col>
    </v-row>
    <v-container class="mt-6">
      <v-row>
        <v-col cols="12" sm="3" class="px-2">
          <v-card elevation="0" outlined class="pt-3 pb-5">
            <v-card-subtitle class="py-0"> วันที่สมัครสมาชิก </v-card-subtitle>
            <v-card-title class="justify-center px-0">
              <h3 class="text-center card-text text-secondary">
                {{ formatDateThai(member.createdAt) }}
              </h3>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" class="px-2">
          <v-card elevation="0" outlined class="pt-3 pb-5">
            <v-card-subtitle class="py-0"> จองไปแล้ว </v-card-subtitle>
            <v-card-title class="justify-center px-0">
              <h3 class="text-center card-text text-secondary">
                {{ orders.length }}
              </h3>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" class="px-2">
          <v-card elevation="0" outlined class="pt-3 pb-5">
            <v-card-subtitle class="py-0">
              จำนวนสมาชิกที่ลงทะเบียนไว้
            </v-card-subtitle>
            <v-card-title class="justify-center px-0">
              <h3 class="text-center card-text text-secondary">
                {{ member.childrenQty }}
              </h3>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3" class="px-2">
          <v-card elevation="0" outlined class="pt-3 pb-5">
            <v-row>
              <v-col class="d-flex justify-end pb-0 pt-2 pr-5">
                <v-icon
                  v-if="!editPoint"
                  color="grey darken-3"
                  size="1.3rem"
                  @click="editPoint = true"
                  >mdi-pencil-outline</v-icon
                >
                <v-icon
                  v-if="editPoint"
                  color="green darken-3"
                  size="1.3rem"
                  @click="onSaveMember()"
                  >mdi-check</v-icon
                >
                <v-icon
                  v-if="editPoint"
                  color="red darken-3"
                  size="1.3rem"
                  @click="editPoint = false"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>

            <v-card-subtitle class="py-0"> แต้มสะสม </v-card-subtitle>
            <v-card-title class="justify-center px-0">
              <h3
                v-if="!editPoint"
                class="text-center card-text text-secondary"
              >
                {{ formatCurrency(member.point) }}
              </h3>
              <v-text-field
                v-model="member.point"
                v-if="editPoint"
                type="number"
                class="py-0 my-0 px-6"
              ></v-text-field>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="mt-5" ref="memberEdit">
      <v-col>
        <v-expansion-panels multiple accordion tile v-model="panels">
          <ExpansionPanelMemberDetail
            v-if="editable"
            :memberProps="member"
            :editableProps="editable"
            @cancelEdit="cancelEdit"
          />
          <ExpansionPanelMemberDetail :memberProps="member" v-else />
          <MemberExpansionPanelChildren
            @input="onUpdateChildren($event)"
            @delete="onDeleteChildren($event)"
          />
          <ExpansionPanelMemberOrderHistory :orders="orders" />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../utils/axios.js";
import Loading from "@/components/Loading.vue";
import ConfirmDialog from "@/components/Confirm.vue";
import MemberExpansionPanelChildren from "../components/member-expansion-panel-children.vue";
import ExpansionPanelMemberDetail from "../components/member-expansion-panel-detail.vue";
import ExpansionPanelMemberOrderHistory from "../components/member-expansion-panel-order-history.vue";
import PackageDialog from "@/components/Package/PackageDialog.vue";

export default {
  components: {
    ExpansionPanelMemberDetail,
    ExpansionPanelMemberOrderHistory,
    MemberExpansionPanelChildren,
    Loading,
    PackageDialog,
    ConfirmDialog,
  },
  data() {
    return {
      loading: false,
      editable: false,
      panels: [0, 1],
      breadItem: [
        {
          text: "รายชื่อสมาชิก",
          exact: true,
          disabled: false,
          link: true,
          to: { path: "/member" },
        },
        {
          text: "รายละเอียดสมาชิก",
          disabled: true,
        },
      ],
      pagination1: {
        page: 1,
      },
      pagination2: {
        page: 1,
      },
      branch: "",
      branch2: "",
      member: {},
      orders: [],
      packages: [],
      searchMemberPackage: "",
      searchPackage: "",
      memberPackage: [],
      editPackage: false,
      editPoint: false,
    };
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    this.branch2 = branch;
    this.getMember();
    this.getAllOrderByMember();
    this.getAllPackage();
    this.getPackageByMember();
  },
  methods: {
    async getAllOrderByMember() {
      try {
        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URI}/order/member/${this.$route.params.memberId}`,
        });

        this.orders = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMember() {
      try {
        this.loading = true;
        const {
          data: {
            data: { member },
          },
        } = await axios.get(`/member/${this.$route.params.memberId}`);
        this.member = member;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onSaveMember() {
      try {
        this.loading = true;
        await axios.put(`/member/${this.$route.params.memberId}`, this.member);
        this.editPackage = false;
        this.editPoint = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getAllPackage() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/package?limit=6&page=${this.pagination1.page}&search=${this.searchPackage}&branch=${this.branch2}`
        );
        this.packages = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getPackageByMember() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/package-member?member.memberId=${this.$route.params.memberId}&limit=8&page=${this.pagination2.page}&search=${this.searchMemberPackage}&package.branch=${this.branch}`
        );
        this.memberPackage = data;
      } catch (error) {
        console.error(error);
      }
    },
    async onChoosePackage(packageId) {
      try {
        const payload = {
          packageId: packageId,
          memberId: this.$route.params.memberId,
        };
        await axios.post("/package-member", payload);
        this.pagination1.page = 1;
        this.getPackageByMember();
      } catch (error) {
        console.error(error);
      }
    },
    async onUpdateChildren(children) {
      try {
        this.loading = true;
        await axios.put(`/children/${children.childrenId}`, children);
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    async onDeleteChildren(childrenId) {
      try {
        const confirm = await this.$refs.confirmDialogue.show();
        if (confirm) {
          this.loading = true;
          await axios.delete(`/children/${childrenId}`);
          this.getMember();
          this.alertSuccess("ดำเนินการสำเร็จ");
        }
      } catch (error) {
        this.alertError("ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    onEditProfile() {
      this.editable = true;
      const el = this.$refs.memberEdit;
      setTimeout(() => {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 200);
    },
    onSearchPackage(event) {
      this.pagination1.page = 1;
      this.searchPackage = event;
      this.getAllPackage();
    },
    onSearchMemberPackage(event) {
      this.pagination2.page = 1;
      this.searchMemberPackage = event;
      this.getPackageByMember();
    },
    async onUpdateMemberPackage(event) {
      try {
        const packageId = event.packageId;
        delete event.packageId;
        const payload = {
          package: {
            expired: event.expired,
            items: event.items,
          },
        };
        await axios.patch(`/package-member/${packageId}`, payload);
        this.getPackageByMember();
      } catch (error) {
        console.error(error);
      }
    },
    async onDeleteMemberPackage(event) {
      try {
        await axios.delete(`/package-member/${event}`);
        this.getPackageByMember();
      } catch (error) {
        console.error(error);
      }
    },
    onChangeBranch2(event) {
      this.branch2 = event;
      this.pagination1.page = 1;
      this.getAllPackage();
    },
    onChangeBranch(event) {
      this.branch = event;
      this.pagination2.page = 1;
      this.searchMemberPackage = "";
      this.getPackageByMember();
    },
    paginationPage(event) {
      this.pagination1.page = event;
      this.getAllPackage();
    },
    paginationPageMemberPackage(event) {
      this.pagination2.page = event;
      this.getPackageByMember();
    },
    cancelEdit(value) {
      this.editable = value;
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    ...mapGetters({
      info: "User/info",
    }),
  },
};
</script>

<style scoped></style>
