<template>
  <v-card elevation="0" outlined class="pt-3 pb-5">
    <v-card-subtitle class="py-0"> ราคา </v-card-subtitle>
    <v-card-title class="justify-center">
      <h3 class="text-center text-secondary">0</h3>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  statusColor(value) {
    switch (value) {
      case "waiting":
        return "orange--text";
      case "pending":
        return "orange--text";
      case "paid":
        return "green--text";
      case "canceled":
        return "red--text";
      case "rejected":
        return "red--text";
      default:
        return "";
    }
  },
};
</script>
<style scoped>
.card-text {
  font-weight: 600;
}
</style>
