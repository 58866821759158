<template>
  <v-dialog
    v-model="resultDialogProps"
    max-width="400"
    transition="dialog-transition"
  >
    <v-card class="py-2" v-if="successProps">
      <v-card-title class="justify-center">
        <h3>เพิ่มสมาชิกสำเร็จ</h3>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-icon size="5.5rem" color="green lighten-2">
          mdi-check-circle
        </v-icon>
      </v-card-actions>

      <v-card-actions class="justify-center">
        <v-btn color="gray lighten-2" depressed @click="closeSuccessDialog"
          >ปิด</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card class="py-2" v-else>
      <v-card-title class="justify-center">
        <h3>เพิ่มสมาชิกไม่สำเร็จ</h3>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-icon size="5.5rem" color="red lighten-2"> mdi-close-circle </v-icon>
      </v-card-actions>

      <v-card-actions class="justify-center">
        <v-btn
          depressed
          color="gray lighten-2"
          @click="$emit('closeDialog', false)"
          >ปิด</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    successProps: {
      type: Boolean,
    },
    resultDialogProps: {
      type: Boolean,
    },
  },
  methods: {
    closeSuccessDialog() {
      this.$emit("closeDialog", false);
      this.$router.push("/member");
    },
  },
};
</script>

<style></style>
