<template>
  <v-card>
    <div class="d-flex justify-space-between mb-4">
      <div><v-icon>mdi-tools</v-icon> จัดการแพ็กเกจ</div>
      <v-btn v-if="type == 'edit'" @click="changeType()" dark color="#369CE0">
        <v-icon class="mr-2">mdi-plus-circle</v-icon>
        เพิ่มแพ็กเกจ
      </v-btn>
    </div>
    <v-form ref="form" @submit.prevent="onSubmit()">
      <v-select
        outlined
        label="สาขา"
        :items="branchItems"
        item-text="text"
        item-value="value"
        v-model="form.branch"
      ></v-select>
      <v-text-field
        outlined
        label="ชื่อแพ็กเกจ"
        v-model="form.title"
      ></v-text-field>
      <v-textarea
        rows="5"
        outlined
        label="รายละเอียด"
        v-model="form.description"
      ></v-textarea>
      <v-row>
        <v-col class="py-0" cols="12" md="6">
          <v-text-field
            outlined
            label="จำนวนที่สามารถใช้ได้"
            type="number"
            v-model="form.items"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-text-field
            outlined
            label="มูลค่าต่อรอบ"
            hint="น้อยกว่าหรือเท่ากับ"
            type="number"
            v-model.number="form.minPrice"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" md="6">
          <v-text-field
            outlined
            label="จำนวนวันหมดอายุ"
            append-icon="mdi-clock-outline"
            type="number"
            v-model.number="form.expired"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" md="6">
          <v-text-field
            :rules="isPriceMoreThanOrEqualMinPrice"
            outlined
            label="ราคา"
            append-icon="mdi-currency-usd"
            type="number"
            v-model.number="form.price"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-color-picker
            v-model="form.color"
            dot-size="25"
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>
        </v-col>
        <v-col cols="12" md="6">
          <CardPackagePreview
            class="pb-4"
            :minPrice="form.minPrice"
            :branch="form.branch"
            :color="form.color"
            :title="form.title"
            :description="form.description"
            :price="form.price"
            :expired="form.expired"
          />
        </v-col>
      </v-row>
      <!-- <v-btn color="#3CB2FF" block dark>บันทึก</v-btn> -->
      <v-btn class="mt-4" color="#369CE0" block dark type="submit"
        >บันทึก</v-btn
      >
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import CardPackagePreview from "@/components/Package/CardPackagePreview.vue";
export default {
  components: {
    CardPackagePreview,
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
    ...mapGetters({
      info: "User/info",
    }),
    isPriceMoreThanOrEqualMinPrice() {
      return [
        (v) =>
          v >= this.form.minPrice || "ราคาต้องมากกว่าหรือเท่ากับมูลค่าต่อรอบ",
      ];
    },
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default: () => ({
        title: "",
        branch: "",
        description: "",
        expired: 0,
        price: 0,
        items: "",
        color: "#369CE0",
        minPrice: 0,
      }),
    },
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.form.branch = branch;
  },
  methods: {
    onSubmit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit("input", this.form);
      }
    },
    changeType() {
      this.$emit("changeType");
    },
  },
};
</script>
