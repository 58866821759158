<template>
  <v-container :class="classObject">
    <Loading v-if="loading" />
    <ConfirmDialog
      message="ต้องการดำเนินการต่อหรือไม่ ?"
      ref="confirmDialogue"
    />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">
          รายการจอง {{ $router.history.length }}
        </h2>
        <PaidDialog
          v-if="dialog"
          :priceAdult="priceAdult"
          :promotion="promotion"
          :moneyToPoint="moneyToPoint"
          :pointToMoney="pointToMoney"
          :point="point"
          :price="price"
          :order="order"
          :package-member="memberPackage"
          @cancel="dialog = false"
          @submit="onPaidSubmit($event)"
        />
      </v-col>
    </v-row>
    <!-- <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row> -->
    <v-row class="mt-10">
      <v-col cols="12">
        <v-card color="px-6 py-6">
          <v-row>
            <v-col cols="12" sm="5"
              ><h3 class="text-secondary">
                หมายเลขการจอง : {{ order && order.orderId }}
              </h3></v-col
            >
            <v-col cols="12" sm="3"
              ><h3 class="text-secondary">
                สาขา: {{ order && order.branch === "trang" ? "ตรัง" : "สาทร" }}
              </h3>
            </v-col>
            <v-col cols="12" sm="4"
              ><h3 class="text-secondary">
                ประเภทการจอง: {{ order && order.type | bookingType }}
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <order-detail-card
      v-if="order"
      :branch="order.branch"
      :orderProps="{
        orderId: order.orderId,
        joinQty: order.joinQty,
        isGroup: order.isGroup,
        status: order.orderStatus,
        schedule: order.schedule,
        participant: order.participant,
        price: order.price,
        orderStatus: order.orderStatus,
      }"
      @selectStatus="onSelectStatus($event)"
      @saveStatus="saveStatus($event)"
      @saveDate="saveDate"
    />

    <v-row v-if="order.orderStatus == 'paid'">
      <!-- {{ order.price }} -->
      <v-col cols="6" sm="3" class="px-2">
        <v-card elevation="0" outlined class="pt-3 pb-5">
          <v-card-subtitle class="py-0">ราคาก่อนลด</v-card-subtitle>
          <v-card-title class="justify-center px-0">
            <h3 class="text-center card-text text-secondary">
              {{ formatCurrency(order.price.total) }}
            </h3>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <v-card elevation="0" outlined class="pt-3 pb-5">
          <v-card-subtitle class="py-0">แต้มที่ใช้</v-card-subtitle>
          <v-card-title class="justify-center px-0">
            <h3 class="text-center card-text text-secondary">
              {{ formatCurrency(order.price.point) }}
            </h3>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <v-card elevation="0" outlined class="pt-3 pb-5">
          <v-card-subtitle class="py-0">ส่วนลด</v-card-subtitle>
          <v-card-title class="justify-center px-0">
            <h3 class="text-center card-text text-secondary">
              {{ formatCurrency(order.price.discount) }}
            </h3>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3" class="px-2">
        <v-card elevation="0" outlined class="pt-3 pb-5">
          <v-card-subtitle class="py-0">ราคาสุทธิ</v-card-subtitle>
          <v-card-title class="justify-center px-0">
            <h3 class="text-center card-text text-secondary">
              {{ formatCurrency(order.price.summary) }}
            </h3>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <order-member-detail
        @input="saveMemberEdit($event)"
        :memberProps="order.member"
      />
    </v-row>
    <v-row
      v-if="order.participant.length > 0 && reloadedAtkDetail"
      class="mt-1"
    >
      <v-expansion-panels multiple class="mt-4">
        <OrderAtkDetail
          class="my-4"
          v-for="(item, index) in order.participant"
          :key="index"
          :index="index + 1"
          :participant="order.participant[index]"
          :atk="atk[index]"
          @input="onUpdateParticipant($event)"
          @delete="onDeleteParticipant($event)"
        />
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import axios from "../../../utils/axios.js";
import ConfirmDialog from "@/components/Confirm.vue";
import Loading from "@/components/Loading.vue";
import PaidDialog from "@/components/Order/PaidDialog.vue";
import OrderAtkDetail from "../components/Order-detail/order-atk-detail.vue";
import orderDetailCard from "../components/Order-detail/order-detail-card.vue";
import OrderMemberDetail from "../components/Order-detail/order-member-detail.vue";

export default {
  components: {
    Loading,
    PaidDialog,
    orderDetailCard,
    OrderMemberDetail,
    OrderAtkDetail,
    ConfirmDialog,
  },
  data() {
    return {
      loading: false,
      branch: "",
      dialog: false,
      reloadedAtkDetail: true,
      isConfirmActionDialog: true,
      historySchedule: [],
      memberPackage: [],
      packagesMember: [],
      priceAdult: 0,
      memberPoint: null,
      // breadItem: [
      //   {
      //     text: "รายการจอง",
      //     exact: true,
      //     disabled: false,
      //     link: true,
      //     href: `${
      //       new URL(document.referrer) ||
      //       new URL(this.$router.internalHistory[0])
      //     }`,
      //   },
      //   {
      //     text: "รายละเอียดการจอง",
      //     disabled: true,
      //   },
      // ],
      packages: {
        title: "",
        quatity: 0,
        expired: "",
        minPrice: 0,
      },
      memberPromotion: null,
      moneyToPoint: [],
      pointToMoney: [],
      atk: [],
      order: {
        member: {
          birthday: "",
          childrenQty: 0,
          email: "",
          firstname: "",
          lastname: "",
          lineId: "",
          lineUid: "",
          memberId: null,
          nationality: "",
          sex: "",
          tel: "",
        },
        participant: [],
        schedule: {
          date: "",
          endTime: "",
          scheduleId: null,
          startTime: "",
        },
      },
    };
  },
  methods: {
    async getOrder() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "get",
          url: `/order/${this.$route.params.orderId}`,
        });

        this.order = data.data;
        this.order.member.birthdayShow = new Date(
          data.data.member.birthday
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        this.priceAdult = this.order.price.totalPriceAdult;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getPoint() {
      try {
        const {
          data: { data },
        } = await axios.get(`/setting/point?branch=${this.order.branch}`);
        this.moneyToPoint = data.moneyToPoint;
        this.pointToMoney = data.pointToMoney;
      } catch (error) {
        console.error(error);
      }
    },
    async getPointByMember() {
      try {
        const {
          data: {
            data: {
              member: { point },
            },
          },
        } = await axios.get(`/member/${this.order.member.memberId}`);
        this.memberPoint = point;
      } catch (error) {
        console.error(error);
      }
    },
    onSelectStatus(value) {
      if (value === "paid") {
        this.dialog = true;
      }
    },
    async getPackageByMember() {
      try {
        const {
          data: {
            data: { docs },
          },
        } = await axios.get(
          `/package-member?member.memberId=${this.order.member.memberId}&limit=100&package.branch=${this.order.branch}`
        );
        this.memberPackage = docs;
      } catch (error) {
        console.error(error);
      }
    },
    async getPromotion() {
      try {
        const {
          data: {
            data: { options },
          },
        } = await axios.get(`schedule/${this.order.schedule.scheduleId}`);
        this.memberPromotion = options?.promotion?.percent;
      } catch (error) {
        console.error(error);
      }
    },
    async onPaidSubmit(event) {
      try {
        this.loading = true;
        const payload = {
          orderStatus: "paid",
          total: event.total,
          discount: event.discount,
          point: event.point,
          memberPackageId: event.memberPackageId,
        };
        await axios({
          method: "patch",
          url: `/order/status/${this.$route.params.orderId}`,
          data: payload,
        });
        await this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    async saveStatus(status) {
      this.loading = true;
      try {
        if (status === "rejected") {
          await axios({
            method: "post",
            url: `/order/${this.$route.params.orderId}/rejected`,
          });
        }
        if (status === "canceled") {
          await axios({
            method: "post",
            url: `/order/${this.$route.params.orderId}/canceled`,
          });
        }
        if (status === "paid") {
          await axios({
            method: "patch",
            url: `/order/status/${this.$route.params.orderId}`,
            data: { orderStatus: status },
          });
        }

        await this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    async saveDate() {
      this.loading = true;
      try {
        await axios({
          method: "patch",
          url: `/order/${this.$route.params.orderId}`,
          data: { "schedule.date": this.order.schedule.date },
        });

        this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    async saveMemberEdit(event) {
      try {
        this.loading = true;
        await axios.patch(`/order/${this.$route.params.orderId}`, {
          member: event,
        });
        this.getOrder();
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertError("เกิดข้อผิดพลาด ดำเนินการไม่สำเร็จ");
      } finally {
        this.loading = false;
      }
    },
    async getAtkByOrder() {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URI}/atk/order/${this.$route.params.orderId}`,
        });
        const atk = data.data;
        this.atk = atk;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async onUpdateParticipant() {
      try {
        this.loading = true;

        await axios.patch(`/order/${this.$route.params.orderId}`, {
          participant: this.order.participant,
        });
        await axios.patch(`/atk/order/${this.$route.params.orderId}`, {});
        await this.getAtkByOrder();
        this.reloadedAtkDetail = false;
        this.$nextTick(() => {
          this.reloadedAtkDetail = true;
        });
        this.alertSuccess("ดำเนินการสำเร็จ");
      } catch (error) {
        this.alertSuccess("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.loading = false;
      }
    },
    async onDeleteParticipant(index) {
      try {
        const confirm = await this.$refs.confirmDialogue.show();
        if (confirm) {
          this.loading = true;
          this.order.participant.splice(index, 1);
          await axios.patch(`/order/${this.$route.params.orderId}`, {
            participant: this.order.participant,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatCurrency(val) {
      if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return val;
    },
    alertSuccess(text) {
      this.$toast.success(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
    alertError(text) {
      this.$toast.error(text, {
        position: "top-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: false,
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    await this.getOrder();
    await this.getAtkByOrder();
    await this.getPackageByMember();
    await this.getPointByMember();
    await this.getPromotion();
    await this.getPoint();
  },
  computed: {
    priceItems() {
      return [
        { text: "คะแนนส่วนลด", value: "point" },
        { text: "คะแนนสะสม", value: "point" },
        { text: "ส่วนลด", value: "discount" },
        { text: "ราคาเต็ม", value: "summary" },
      ];
    },
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    ...mapGetters({
      info: "User/info",
    }),
    promotion() {
      if (this.memberPromotion) {
        return this.memberPromotion;
      }
      return 0;
    },
    point() {
      if (this.memberPoint) {
        return this.memberPoint;
      }
      return 0;
    },
    price() {
      if (this.order && this.order.price) return this.order.price.total;
      return 0;
    },
    packagMemberItems() {
      if (this.memberPackage.length > 0) {
        const packagesFilter = this.memberPackage.filter((item) => {
          const minute = dayjs(item.package.expired).diff(dayjs(), "minute");
          return item.package.items > 0 && minute > 0;
        });
        const arr = [];
        for (let i = 0; i < packagesFilter.length; i++) {
          arr.push({
            text: packagesFilter[i].package.title,
            value: packagesFilter[i].memberPackageId,
          });
        }
        return arr;
      }
      return [];
    },
  },
};
</script>

<style></style>
