<template>
  <v-col cols="6" sm="3">
    <Loading v-if="loading" />
    <v-btn
      class="white--text"
      color="green lighten-2"
      block
      depressed
      @click="dialog = true"
      >ยืนยันการแก้ไข</v-btn
    >
    <v-dialog
      v-model="dialog"
      max-width="500"
      :persistent="loading"
      transition="dialog-transition"
    >
      <v-card :loading="loading">
        <v-card-title>คุณต้องการจะแก้ไขข้อมูลส่วนตัวใช่หรือไม่</v-card-title>
        <v-card-text>กรุณาตรวจสอบข้อมูลให้ถูกต้องก่อนกดยืนยัน</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="6" sm="3">
            <v-btn
              color="orange lighten-2"
              class="white--text"
              @click="dialog = false"
              depressed
              block
              :disabled="loading"
              >ย้อนกลับ</v-btn
            >
          </v-col>
          <v-col cols="6" sm="3">
            <v-btn
              color="green lighten-2"
              class="white--text"
              depressed
              block
              :disabled="loading"
              :loading="loading"
              @click="confirmEdit"
              >ยืนยัน</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="resultDialog"
      max-width="400"
      transition="dialog-transition"
    >
      <v-card class="py-2" v-if="success">
        <v-card-title class="justify-center">
          <h3>แก้ไขข้อมูลส่วนตัวสำเร็จ</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-icon size="5.5rem" color="green lighten-2">
            mdi-check-circle
          </v-icon>
        </v-card-actions>

        <v-card-actions class="justify-center">
          <v-btn color="gray lighten-2" depressed @click="closeSuccessDialog"
            >ปิด</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-card class="py-2" v-else>
        <v-card-title class="justify-center">
          <h3>แก้ไขข้อมูลส่วนตัวไม่สำเร็จ</h3>
        </v-card-title>
        <v-card-actions class="justify-center">
          <v-icon size="5.5rem" color="red lighten-2">
            mdi-close-circle
          </v-icon>
        </v-card-actions>

        <v-card-actions class="justify-center">
          <v-btn depressed color="gray lighten-2" @click="resultDialog = false"
            >ปิด</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import axios from "axios";
import Loading from "@/components/Loading.vue";

export default {
  components: { Loading },
  props: {
    memberEditProps: {
      type: Object,
    },
  },
  data() {
    return {
      success: null,
      resultDialog: false,
      loading: false,
      dialog: false,
      memberEdit: {},
    };
  },
  methods: {
    async confirmEdit() {
      try {
        this.loading = true;
        await axios({
          method: "PUT",
          url: `${process.env.VUE_APP_API_URI}/member/${this.$route.params.memberId}`,
          data: this.memberEditProps,
        });
        this.loading = false;
        this.dialog = false;
        this.success = true;
        this.resultDialog = true;
      } catch (error) {
        this.success = false;
        this.resultDialog = true;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    closeSuccessDialog() {
      this.resultDialog = false;
      this.$router.go(0);
    },
  },
  mounted() {
    this.memberEdit = this.memberEditProps;
  },
};
</script>

<style></style>
