<template>
  <v-dialog
    ref="confirm"
    v-model="isVisible"
    v-bind="attrs"
    v-on="{ ...$listeners }"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />

    <v-card>
      <v-card-title class="text-center red white--text lighten-2">
        <v-icon class="mr-4" color="white">mdi-alert-circle</v-icon>
        {{ title }}
      </v-card-title>

      <v-card-text class="font-weight-bold my-6">
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="_cancel()">ยกเลิก</v-btn>
        <v-btn color="primary" text @click="_confirm()">ดำเนินการต่อ</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "คำเตือน",
    },
    message: {
      type: String,
      default: "ต้องการลบ Package นี้หรือไม่?",
    },
  },
  data() {
    return {
      defaultAttrs: {
        width: 500,
        persistent: true,
      },
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null,
    };
  },
  computed: {
    attrs() {
      return {
        ...this.defaultAttrs,
        ...this.$attrs,
      };
    },
  },
  mounted() {},
  methods: {
    show() {
      // Once we set our config, we tell the popup modal to open
      // Return promise so the caller can get results
      this.isVisible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _confirm() {
      this.isVisible = false;
      this.resolvePromise(true);
    },

    _cancel() {
      this.isVisible = false;
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>
