<template>
  <v-container :class="classObject">
    <Loading v-if="loading" />
    <v-row class="mt-2">
      <v-col cols="12" class="py-0">
        <h2 class="text-secondary header">รายการจอง</h2>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" lg="5" class="py-0">
        <v-breadcrumbs divider=">" :items="breadItem" class="breadcrumbs">
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row justify="start" class="mt-4">
      <v-col cols="12" md="3" class="py-0">
        <v-text-field
          label="ค้นหา (หมายเลขการจอง ชื่อ เบอร์โทรศัพท์)"
          hint="กด Enter เพื่อค้นหา"
          append-icon="mdi-magnify"
          v-model="search"
          @keydown.enter="onSearch()"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          @change="onChangeFilter('type')"
          v-model="filter.type"
          class="py-0"
          :items="typeItems"
          item-text="text"
          item-value="value"
          label="ประเภท"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          @change="onChangeFilter('atk')"
          v-model="filter.atkStatus"
          class="py-0"
          :items="atkItems"
          item-text="text"
          item-value="value"
          label="ผลตรวจ ATK / หนังสือขอเข้าเยี่ยมชม"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          @change="onChangeFilter('status')"
          v-model="filter.status"
          class="py-0"
          :items="statusItems"
          item-text="text"
          item-value="value"
          label="สถานะ"
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="start" class="mt-4">
      <v-col cols="12" md="3" class="py-0"></v-col>
      <v-col cols="12" md="3" class="py-0"></v-col>
      <v-col cols="12" md="3" class="py-0"></v-col>
      <v-col cols="12" md="3" class="py-0">
        <v-select
          @change="changeBranch()"
          v-model="branch"
          class="py-0"
          :items="branchItems"
          item-text="text"
          item-value="value"
          label="สาขา"
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <ScheduleHistoryDialog
          :count="countNotify"
          :schedule="historySchedule"
          @input="onReaded()"
        />
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-2">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="orders"
          style="overflow-x: scroll"
          class="elevation-0 row-pointer"
          :loading="loading"
          hide-default-footer
          @click:row="
            $event.isGroup === false
              ? $router.push(`/order/detail/${$event.orderId}`)
              : $router.push(`/order/group/${$event.orderId}`)
          "
        >
          <template #loading> กำลังโหลดข้อมูล </template>
          <template #no-data> ไม่พบข้อมูล </template>
          <!-- <template v-slot:[`item.schedule`]="{ item }">
            {{ formatDate(item.schedule.date) }}
          </template> -->
          <template v-slot:[`item.orderId`]="{ item }">
            <a style="text-decoration: underline">{{ item.orderId }}</a>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.type | bookingType }}
          </template>
          <template v-slot:[`item.branch`]="{ item }">
            {{ item.branch === "trang" ? "ตรัง" : "สาทร" }}
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ item.createdOn | formatMiniDate }}
          </template>
          <template v-slot:[`item.schedule.date`]="{ item }">
            {{ item.schedule.date | formatMiniDate }}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            <div v-if="item.isGroup">
              {{
                formatSchedule(item.schedule.startTime, item.schedule.endTime)
              }}
            </div>
            <div v-else>
              {{
                formatSchedule(item.schedule.startTime, item.schedule.endTime)
              }}
            </div>
          </template>
          <template v-slot:[`item.atkStatus`]="{ item }">
            <span :class="item.atkStatus ? 'green--text' : 'red--text'">
              {{ item.atkStatus ? "ส่งแล้ว" : "ยังไม่ส่ง" }}
            </span>
          </template>
          <template v-slot:[`item.statusShow`]="{ item }">
            <div
              v-if="item.orderStatus === 'paid' && item.price.summary <= 0"
              :class="statusColor(item.orderStatus)"
            >
              คอนเฟิร์ม
            </div>
            <div v-else :class="statusColor(item.orderStatus)">
              {{ item.orderStatus | formatStatus }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row justify="end" class="my-5">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="7"
        color="#3CB2FE"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="onChangePage($event)"
      ></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "../../../utils/axios.js";
import Loading from "@/components/Loading.vue";
import ScheduleHistoryDialog from "@/components/Schedule/ScheduleHistoryDialog.vue";
export default {
  components: { Loading, ScheduleHistoryDialog },
  computed: {
    classObject() {
      if (this.$vuetify.breakpoint.width > 960) {
        return "mt-0";
      } else {
        return "mt-5";
      }
    },
    ...mapGetters({
      info: "User/info",
    }),
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
    typeItems() {
      return [
        { text: "ทั้งหมด", value: "all" },
        { text: "ปกติ", value: "normal" },
        { text: "กิจกรรม", value: "event" },
        { text: "กลุ่ม", value: "group" },
      ];
    },
    atkItems() {
      return [
        { text: "ทั้งหมด", value: "all" },
        { text: "ส่งแล้ว", value: "true" },
        { text: "ยังไม่ส่ง", value: "false" },
      ];
    },
    statusItems() {
      return [
        { text: "ทั้งหมด", value: "all" },
        { text: "ชำระเงินแล้ว / คอนเฟิร์ม", value: "success" },
        { text: "รอคอนเฟิร์ม", value: "waiting" },
        { text: "ปฏิเสธ", value: "rejected" },
        { text: "ยกเลิก", value: "canceled" },
      ];
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      page: 1,
      totalPages: 1,
      countNotify: 0,
      branch: "",
      historySchedule: [],
      filter: {
        type: this.$route.query.type || "all",
        atkStatus: this.$route.query.type || "all",
        status: this.$route.query.type || "all",
      },
      headers: [
        {
          text: "หมายเลขการจอง",
          value: "orderId",
          sortable: false,
        },
        {
          text: "สาขา",
          value: "branch",
          sortable: false,
        },
        {
          text: "ประเภทการจอง",
          value: "type",
          sortable: false,
          width: "10rem",
          align: "center",
        },
        {
          text: "วันที่ทำรายการ",
          value: "createdOn",
          sortable: false,
          width: "8rem",
        },
        {
          text: "วันที่จอง",
          value: "schedule.date",
          sortable: false,
          width: "8rem",
        },
        {
          text: "เวลา",
          value: "time",
          width: "9rem",
          sortable: false,
        },
        {
          text: "ชื่อ",
          value: "member.firstname",
          sortable: false,
          width: "8rem",
        },
        {
          text: "นามสกุล",
          value: "member.lastname",
          sortable: false,
          width: "8rem",
        },
        {
          text: "หมายเลขโทรศัพท์",
          value: "member.tel",
          align: "center",
          sortable: false,
          width: "9rem",
        },
        {
          text: "จำนวนผู้เข้าร่วม",
          value: "joinQty",
          align: "center",
          width: "7rem",
          sortable: false,
        },
        {
          text: "ผลตรวจ ATK / หนังสือขอเข้าเยี่ยมชม",
          value: "atkStatus",
          align: "center",
          width: "15rem",
          sortable: false,
        },
        {
          text: "สถานะ",
          value: "statusShow",
          align: "center",
          width: "8rem",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      breadItem: [
        {
          text: "รายการจอง",
          disabled: true,
        },
      ],
      orders: [],
    };
  },
  mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    this.page = parseInt(this.$route.query.page) || 1;
    this.search = this.$route.query.search || "";
    this.filter.type = this.$route.query.type || "all";
    this.filter.atkStatus = this.$route.query.atkStatus || "all";
    this.filter.status = this.$route.query.status || "all";
    this.getAllOrders();
    this.getScheduleHistory();
  },
  methods: {
    statusColor(value) {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
    async getAllOrders() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_URI}/order/all/order?page=${this.page}&search=${this.search}&type=${this.filter.type}&atkStatus=${this.filter.atkStatus}&orderStatus=${this.filter.status}&branch=${this.branch}`;
      try {
        const {
          data: { data },
        } = await axios({
          method: "GET",
          url,
          // url: `${process.env.VUE_APP_API_URI}/order/all/order?page=${this.page}&search=${this.search}`,
        });

        this.orders = data.docs;
        // this.orders = data.docs;
        this.totalPages = data.totalPages;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getScheduleHistory() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${process.env.VUE_APP_API_URI}/order-history?branch=${this.branch}`
        );
        const count = data.filter((item) => !item.read).length;
        this.historySchedule = data;
        this.countNotify = count;
      } catch (error) {
        console.error(error);
      }
    },
    async onReaded() {
      try {
        const arr = [];
        for (let i = 0; i < this.historySchedule.length; i++) {
          if (!this.historySchedule[i].read) {
            arr.push(this.historySchedule[i]._id);
          }
        }
        // console.log(arr);
        await axios.patch("/order-history/readed", { id: arr });
        this.countNotify = 0;
      } catch (error) {
        console.error(error);
      }
    },
    async onSearch() {
      try {
        this.page = 1;
        this.$router.replace(
          `/order?page=${this.page}&search=${this.search}&type=${this.filter.type}&atkStatus=${this.filter.atkStatus}&status=${this.filter.status}`
        );
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },
    async onChangeFilter() {
      try {
        this.page = 1;
        this.$router.replace(
          `/order?page=${this.page}&search=${this.search}&type=${this.filter.type}&atkStatus=${this.filter.atkStatus}&status=${this.filter.status}`
        );
        this.$router.go();
      } catch (error) {
        console.error(error);
      }
    },
    async changeBranch() {
      await this.getAllOrders();
      await this.getScheduleHistory();
    },
    onChangePage(event) {
      this.$router.replace(
        `/order?page=${event}&search=${this.search}&type=${this.filter.type}&atkStatus=${this.filter.atkStatus}&status=${this.filter.status}`
      );
      this.$router.go();
    },
  },
};
</script>

<style scoped>
::v-deep label.v-label.theme--light {
  color: rgba(0, 0, 0, 0.38) !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
