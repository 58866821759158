<template>
  <div>
    <v-app-bar app :value="isMobile" color="#3db2ff">
      <v-app-bar-nav-icon>
        <v-icon size="2rem" @click.stop="drawer = !drawer" color="white"
          >mdi-menu</v-icon
        >
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      app
      height="100%"
      mobile-breakpoint="960"
      v-model="drawer"
      color="#3db2ff"
      class="elevation-3"
    >
      <v-list-item>
        <div class="d-flex flex-column align-center mb-2" style="width: 100%">
          <img
            src="/svg/PlanToys_Logo-01.svg"
            class="img-logo"
            alt="logo"
            width="100px"
          />
          <div class="text-center font-weight-bold white--text">
            สาขา{{ info.profile.branch | formatBranch }}
          </div>
        </div>
      </v-list-item>

      <div
        style="height: calc(100% - 132px)"
        class="d-flex flex-column justify-space-between"
      >
        <v-list dark>
          <v-list-item
            v-for="(path, i) in route"
            :key="i"
            link
            :to="path.path"
            class="py-0"
          >
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon>{{ path.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ path.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            :value="false"
            color="white"
            prepend-icon="mdi-tools"
            class="ml-5"
          >
            <template v-slot:activator>
              <v-list-item-title class="white--text">จัดการ</v-list-item-title>
            </template>
            <v-list-item
              v-for="(path, i) in routeManage"
              :key="i"
              link
              :to="path.path"
              class="py-0"
            >
              <v-list-item-icon class="ml-5 mr-5">
                <v-icon>{{ path.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ path.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item link :to="'/ticket'" class="py-0">
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon>mdi-printer</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> พิมพ์สายรัดข้อมือ </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            :value="false"
            color="white"
            prepend-icon="mdi-chart-timeline"
            class="ml-5"
          >
            <template v-slot:activator>
              <v-list-item-title class="white--text">รายงาน</v-list-item-title>
            </template>
            <v-list-item
              v-for="(path, i) in routeOrder"
              :key="i"
              link
              :to="path.path"
              class="py-0"
            >
              <v-list-item-icon class="ml-5 mr-5">
                <v-icon>{{ path.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ path.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item link :to="'/setting'" class="py-0">
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> ตั้งค่า </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="info.profile.id === authId"
            link
            :to="'/logs'"
            class="py-0"
          >
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon>mdi-clipboard-list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Logs </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="info.profile.id === authId"
            link
            to="management-admin"
            class="py-0"
          >
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> จัดการแอดมิน </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dark>
          <v-list-item link :to="'/profile'">
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon> mdi-account </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ info.profile.firstname }} {{ info.profile.lastname }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="logout()">
            <v-list-item-icon class="ml-5 mr-5">
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> ออกจากระบบ </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Navbar",
  props: {
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      drawer: null,
      route: [
        {
          title: "สมาชิก",
          path: "/member",
          icon: "mdi-account-group",
        },
        {
          title: "รายการจอง",
          path: "/order",
          icon: "mdi-file-document",
        },
        // {
        //   title: "ประวัติการเปลี่ยนรอบ",
        //   path: "/history-schedule",
        //   icon: "mdi-clipboard-text-clock",
        // },
        // {
        //   title: "สร้างรายการจอง",
        //   path: "/schedule",
        //   icon: "mdi-calendar-plus",
        // },
      ],
      routeOrder: [
        {
          title: "รายงานออเดอร์",
          path: "/report",
          icon: "mdi-file-chart ",
        },
        {
          title: "รายงานรายลูกค้า",
          path: "/report-personalized",
          icon: "mdi-clipboard-account",
        },
        {
          title: "กราฟสรุป",
          path: "/report-graph",
          icon: "mdi-chart-line-variant",
        },
      ],
      routeManage: [
        {
          title: "จัดการหน้ากิจกรรม",
          path: "/booking",
          icon: "mdi-calendar-month",
        },
        {
          title: "จัดการแพ็กเกจ",
          path: "/package",
          icon: "mdi-card-multiple",
        },
        {
          title: "สร้างออเดอร์",
          path: "/create-order",
          icon: "mdi-toy-brick-plus",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    authId() {
      return process.env.VUE_APP_AUTH_ID;
    },
  },
  methods: {
    ...mapActions({
      clearInfo: "User/clearInfo",
      setInfo: "User/setInfo",
    }),
    logout() {
      this.setInfo({
        profile: {
          id: null,
          firstname: "",
          lastname: "",
        },
        token: "",
      });
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  color: #305279 !important;
  background-color: white;
}
.v-list-item--active::before {
  opacity: 0 !important;
}
.img-logo {
  width: 100px;
  text-align: center;
  margin: 0 auto;
}
</style>
