<template>
  <v-card outlined elevation="0">
    <!-- <v-row class="mt-1">
      <v-col class="d-flex justify-end pb-0 pt-2 pr-7">
        <v-icon color="grey darken-3" size="1.3rem">mdi-pencil-outline</v-icon>
      </v-col>
    </v-row> -->
    <v-card-title>รายละเอียดการจอง</v-card-title>
    <v-col cols="12" md="10" class="px-0 py-0">
      <v-container class="px-10">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="order.organizationName"
              disabled
              label="ชื่อองค์กร"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.contactFirstname"
              disabled
              label="ชื่อจริง (ผู้ติดต่อ)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.contactLastname"
              disabled
              label="นามสกุล (ผู้ติดต่อ)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.contactEmail"
              disabled
              label="อีเมล (ผู้ติดต่อ)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="order.contactTel"
              disabled
              label="เบอร์โทรศัพท์ (ผู้ติดต่อ)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-title>ผู้เข้าร่วมแบ่งตามช่วงอายุ</v-card-title>
        <v-row>
          <v-col
            v-for="(patricipant, index) of participants"
            :key="index"
            cols="12"
            sm="6"
          >
            <v-text-field
              :value="patricipant.count + ' คน'"
              disabled
              :label="'อายุ' + ' ' + patricipant.range"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-card>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    participants() {
      return this.order.participants;
    },
    sexItems() {
      return [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
        { text: "ไม่ระบุ", value: "undefine" },
      ];
    },
  },
};
</script>
