import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";

dayjs.extend(buddhistEra);

const dateFormat = {
  default(date, format = "DD MMMM BBBB") {
    return dayjs(date).locale("th").format(format);
  },
  age(date) {
    return dayjs(new Date()).diff(dayjs(date), "year");
  },
};

export default dateFormat;
